import { CreateFeasibilityStudy } from './feasibility-study.dto';
import { httpClient2 } from 'infrastructure/admi-client/http-client';

export const admiFeasibilityStudyService = {
    async createFeasibilityStudy(dto: CreateFeasibilityStudy): Promise<[Blob, string]> {
        const response = await httpClient2.post<Blob>('/v1/feasibility-study', dto, { responseType: 'blob' });
        const filenameMatch = response.headers['content-disposition'].match(/filename="([^"]+)"/);

        return [response.data, filenameMatch[1]];
    },
};
