import React, { useEffect, useState } from 'react';
import { FormControl, FormLabel, Typography, Input, Select, Option } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { OnsiteDataPoint } from 'features/onsite/onsite.dto';
import { formFieldBuilder, handleSelect } from 'common/formikUtils';
import ImageSelector from 'common/components/ImageSelector';
import { ifNotNull } from 'common/helpers';

interface Properties {
    formData: { [reference: string]: OnsiteDataPoint };
    formik: any;
}

function FragmentElektrischerAnschluss(props: Properties) {
    console.debug('FragmentElektrischerAnschluss', props);
    const { formData, formik } = props;

    const formField = (name: string) => formFieldBuilder(formik, name);

    const { t } = useTranslation();

    const [meterCabinet, setMeterCabinet] = useState<string[]>([]);
    const [installationZoneMeterCabinetPhoto, setInstallationZoneMeterCabinetPhoto] = useState<string[]>([]);
    const [networkConnectionPhoto, setNetworkConnectionPhoto] = useState<string[]>([]);
    const [subDistributionPhoto, setSubDistributionPhoto] = useState<string[]>([]);
    const [systemComponentsPhoto, setSystemComponentsPhoto] = useState<string[]>([]);

    useEffect(() => {
        ifNotNull(formData['installation-zone-meter-cabinet'], (value) =>
            formik.setFieldValue('IZ_ZAEHLERSCHRANK', value['response']),
        );
        ifNotNull(formData['network-connection'], (value) =>
            formik.setFieldValue('IZ_NETZWERKANSCHLUSS', value['response']),
        );
        ifNotNull(formData['sub-distribution'], (value) =>
            formik.setFieldValue('IZ_UNTERVERTEILUNG', value['response']),
        );
        ifNotNull(formData['system-components'], (value) =>
            formik.setFieldValue('IZ_SYSTEMKOMPONENTEN', value['response']),
        );

        ifNotNull(formData['meter-cabinet'], (value) => {
            setMeterCabinet(value['photos'].map((photo: any) => photo.url));
        });

        ifNotNull(formData['installation-zone-meter-cabinet-photo'], (value) => {
            setInstallationZoneMeterCabinetPhoto(value['photos'].map((photo: any) => photo.url));
        });

        ifNotNull(formData['network-connection-photo'], (value) => {
            setNetworkConnectionPhoto(value['photos'].map((photo: any) => photo.url));
        });

        ifNotNull(formData['sub-distribution-photo'], (value) => {
            setSubDistributionPhoto(value['photos'].map((photo: any) => photo.url));
        });

        ifNotNull(formData['system-components-photo'], (value) => {
            setSystemComponentsPhoto(value['photos'].map((photo: any) => photo.url));
        });
    }, [formData]);

    return (
        <>
            <Typography level="h3">Elektrischer Anschluss</Typography>
            <FormControl required>
                <FormLabel>Wie ist der Zustand der E-Anlage/ZS</FormLabel>
                <Select
                    sx={{ width: '20rem' }}
                    value={formik.values.roof_cladding}
                    onChange={handleSelect(formik, 'ZUSTAND_E_ANLAGE')}
                >
                    <Option key={0} value="good">
                        Gut (PV-Anlage kann daran installiert werden)
                    </Option>
                    <Option key={1} value="unclear_single_family">
                        Unklar (Standard Einfamilienhaus ZS)
                    </Option>
                    <Option key={2} value="replace_single_family">
                        Muss erneuert werden (Standard Einfamilienhaus ZS)
                    </Option>
                    <Option key={3} value="unclear_large_nshv">
                        Unklar (große NSHV wie in Schulen)
                    </Option>
                </Select>
            </FormControl>

            <FormControl required>
                <FormLabel>Aufnahme Zählerschrank</FormLabel>
                <ImageSelector
                    name="AUFNAHME_ZAEHLERSCHRANK"
                    formik={formik}
                    imageUrls={meterCabinet}
                    required={true}
                />
            </FormControl>

            <FormControl>
                <FormLabel>Aufnahme Zähler</FormLabel>
                <ImageSelector name="AUFNAHME_ZAEHLER" formik={formik} multiple={true} />
            </FormControl>

            <FormControl>
                <FormLabel>Aufnahme Zähleraufhängung</FormLabel>
                <ImageSelector name="AUFNAHME_ZAEHLERAUFHAENGUNG" formik={formik} />
            </FormControl>

            <FormControl>
                <FormLabel>Typenschild Zähler</FormLabel>
                <ImageSelector name="AUFNAHME_TYP_ZAEHLERSCHRANK" formik={formik} />
            </FormControl>

            <Typography level="h3">Installationszonen</Typography>
            <FormControl required>
                <FormLabel>Installationszone Zählerschrank</FormLabel>
                <Input type="text" placeholder="Installationszone Zählerschrank" {...formField('IZ_ZAEHLERSCHRANK')} />
            </FormControl>

            <FormControl>
                <FormLabel>Aufnahme Installationszone Zählerschrank</FormLabel>
                <ImageSelector
                    name="AUFNAHME_IZ_ZAEHLERSCHRANK"
                    formik={formik}
                    imageUrls={installationZoneMeterCabinetPhoto}
                />
            </FormControl>

            <FormControl required>
                <FormLabel>Installationszone Netzwerkanschluss</FormLabel>
                <Input
                    type="text"
                    placeholder="Installationszone Netzwerkanschluss"
                    {...formField('IZ_NETZWERKANSCHLUSS')}
                />
            </FormControl>

            <FormControl>
                <FormLabel>Aufnahme Installationszone Netzwerkanschluss</FormLabel>
                <ImageSelector
                    name="AUFNAHME_IZ_NETZWERKANSCHLUSS"
                    formik={formik}
                    imageUrls={networkConnectionPhoto}
                />
            </FormControl>

            <FormControl required>
                <FormLabel>Installationszone Unterverteilung</FormLabel>
                <Input
                    type="text"
                    placeholder="Installationszone Unterverteilung"
                    {...formField('IZ_UNTERVERTEILUNG')}
                />
            </FormControl>

            <FormControl>
                <FormLabel>Aufnahme Installationszone Unterverteilung</FormLabel>
                <ImageSelector name="AUFNAHME_IZ_UNTERVERTEILUNG" formik={formik} imageUrls={subDistributionPhoto} />
            </FormControl>

            <FormControl required>
                <FormLabel>Installationszone Systemkomponenten</FormLabel>
                <Input
                    type="text"
                    placeholder="Installationszone Systemkomponenten"
                    {...formField('IZ_SYSTEMKOMPONENTEN')}
                />
            </FormControl>

            <FormControl>
                <FormLabel>Installationszone der Systemkomponenten (Wechselrichter und Energiespeicher)</FormLabel>
                <ImageSelector
                    name="AUFNAHME_IZ_WECHSELRICHTER_AND_SPEICHER"
                    formik={formik}
                    imageUrls={systemComponentsPhoto}
                />
            </FormControl>

            <FormControl>
                <FormLabel>{t('Relative Position of the Rooms to each other')}</FormLabel>
                <ImageSelector name="RELATIVE_POSITION_OF_THE_ROOMS_TO_EACH_OTHER" formik={formik} />
            </FormControl>

            <FormControl>
                <FormLabel>Installationszone Feuerwehrschalter</FormLabel>
                <Input type="text" placeholder="Installationszone Feuerwehrschalter" {...formField('IZ_FEUERWEHR')} />
            </FormControl>
        </>
    );
}

const mapStateToProps = function (state: any) {
    return {
        formData: state.onsite.formData,
    };
};

export default connect(mapStateToProps)(FragmentElektrischerAnschluss);
