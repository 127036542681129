import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { center } from '@turf/turf';

import { admiPVAnalysisService } from './admi-pv-analysis.service';
import { admiPVCalculatorService } from 'features/pv-calculator/admi-pv-calculator.service';
import { admiGlobalTaggerService } from 'features/global-tagger/admi-global-tagger.service';

import {
    GeoLocation,
    LocationSearch,
    PvAnalysisBuildingMetadata,
    PvAnalysisVariationBuilding,
    PvAnalysisVariationCalculatorResult,
    PvAnalysisVariationRoofSegment,
} from './pv-analysis.dto';
import { Optimizer, PVResultDto, RoofSegmentDto } from 'features/pv-calculator/pv-calculator.dto';
import { BuildingSearch, BuildingsMetadata, RealEstate } from 'features/global-tagger/global-tagger.dto';
import { DEFAULT_PV_PARAMS, OptimizerMode, PVInputParams } from 'features/pv-calculator/components/PVInput';
import { RootState } from 'store';

export const loadPvAnalysisVariant = createAsyncThunk(
    'pvAnalysis/loadPvAnalysisVariant',
    async (uuid: string, _thunkApi) => {
        return admiPVAnalysisService.getPVAnalysisVariant(uuid);
    },
);

export const loadRealEstateData = createAsyncThunk(
    'pvAnalysis/loadRealEstateData',
    async (dataSearch: LocationSearch, _thunkApi) => {
        const mapToPvAnalysisBuildings = (dto: RealEstate): PvAnalysisVariationBuilding[] =>
            dto.buildings.map((building) => ({
                name: building.name,
                buildingFunctionCode: building.buildingFunction.code,
                code: building.code,
                geometry: building.geometry,
                roofSegments: [],
            }));

        return mapToPvAnalysisBuildings(await admiGlobalTaggerService.loadRealEstateData(dataSearch));
    },
);

export const loadBuildingToSelection = createAsyncThunk(
    'pvAnalysis/addBuildingToSelection',
    async (buildingSearch: BuildingSearch, _thunkApi) => {
        const building = await admiGlobalTaggerService.loadBuildingData(buildingSearch);

        return {
            name: building.name,
            buildingFunctionCode: building.buildingFunction.code,
            code: building.code,
            geometry: building.geometry,
            roofSegments: building.roof.roofSegments.map((roofSegment) => ({
                moduleArea: roofSegment.area,
                azimuthDegrees: roofSegment.azimuthDegrees,
                tiltDegrees: roofSegment.tiltDegrees,
                kwp: roofSegment.roofSegmentSolarPotential.kwp,
                kwhPerKwp: roofSegment.roofSegmentSolarPotential.kwhPerKwp,
            })),
        };
    },
);

export const calculatePvResults = createAsyncThunk(
    'pvAnalysis/calculatePvResults',
    async (params: { selectedBuildings: PvAnalysisVariationBuilding[]; pvParams: PVInputParams }, thunkApi) => {
        // Map the selected buildings to the format the PV calculator expects
        const pvCalculatorRoofSegments = params.selectedBuildings
            .map((building, buildingIndex) =>
                building.roofSegments.map((segment): RoofSegmentDto => {
                    return {
                        building_id: `${buildingIndex}:${building.code}`,
                        direction: segment.azimuthDegrees,
                        roof_pitch: segment.tiltDegrees,
                        kwp: segment.kwp,
                        kwh_kwp: segment.kwhPerKwp,
                    };
                }),
            )
            .flat();

        const { useQAPVCalculator } = (thunkApi.getState() as RootState).featureFlags;
        const [centerLong, centerLat] = center(params.selectedBuildings[0].geometry).geometry.coordinates;

        try {
            const pvParams = params.pvParams;
            const [optimizedResult, fullRoofResult] = await Promise.all([
                admiPVCalculatorService.doPVOptimization(
                    pvCalculatorRoofSegments,
                    pvParams.optimizer,
                    pvParams.buildingType,
                    pvParams.loadProfiles,
                    pvParams.chpProductionProfile,
                    pvParams.fundingRate / 100,
                    pvParams.constrainFullRoofTo100KWp,
                    pvParams.productionOptimizerMode === OptimizerMode.Manual ? pvParams.targetProduction : undefined,
                    pvParams.batteryOptimizerMode === OptimizerMode.Manual ? pvParams.batteryKwpFactor : undefined,
                    centerLat,
                    centerLong,
                    useQAPVCalculator,
                ),
                admiPVCalculatorService.doPVOptimization(
                    pvCalculatorRoofSegments,
                    Optimizer.FULL_ROOF,
                    pvParams.buildingType,
                    pvParams.loadProfiles,
                    pvParams.chpProductionProfile,
                    0,
                    pvParams.constrainFullRoofTo100KWp,
                    undefined,
                    OptimizerMode.Auto,
                    centerLat,
                    centerLong,
                    useQAPVCalculator,
                ),
            ]);

            return {
                optimizedResult,
                fullRoofResult,
                // Store these also so they don't change when the user changes the input
                optimizer: pvParams.optimizer,
                fundingRate: pvParams.fundingRate,
                baseUsageEstimated: admiPVCalculatorService.isBaseUsageEstimated(pvParams.loadProfiles),
            };
        } catch (e: any) {
            return thunkApi.rejectWithValue(admiPVCalculatorService.errorResponseToDescription(e));
        }
    },
);

export const loadPotentialBuildings = createAsyncThunk(
    'pvAnalysis/loadPotentialBuildings',
    async (dataSearch: LocationSearch, _thunkApi) => {
        const mapToPvAnalysisBuildingsMetadata = (dto: BuildingsMetadata): PvAnalysisBuildingMetadata[] =>
            dto.buildingsMetadata.map((buildingMetadata) => ({
                code: buildingMetadata.code,
                function: buildingMetadata.functionCode,
                geometry: buildingMetadata.bbox2d,
                filePath: buildingMetadata.filePath,
            }));

        return mapToPvAnalysisBuildingsMetadata(await admiGlobalTaggerService.loadBuildingsMetaData(dataSearch));
    },
);

export const importBuilding = createAsyncThunk(
    'pvAnalysis/importBuilding',
    async (buildingMetadata: PvAnalysisBuildingMetadata, _thunkApi) => {
        const response = await admiGlobalTaggerService.importBuilding(buildingMetadata.filePath, buildingMetadata.code);
        return response;
    },
);

export interface State {
    location: GeoLocation | null;
    errorMessage: string;
    buildings: PvAnalysisVariationBuilding[];
    buildingsMetadata: PvAnalysisBuildingMetadata[];
    selectedBuildingMetadata: PvAnalysisBuildingMetadata | null;
    highlightedBuilding: string;
    isMapLoading: boolean;
    isCalculationLoading: boolean;
    isImportBuildingLoading: boolean;
    showOnlySelectedBuildings: boolean;

    editedVariant: {
        pvAnalysisUuid?: string;
        pvAnalysisName: string;
        name: string;
        author?: string;
        buildings: PvAnalysisVariationBuilding[];
        maxSystemCapacityAdjustment: number;
        pvParams: PVInputParams;
        calculatorResults: CalculatorResults | null;
    };
}

interface CalculatorResults {
    optimizedResult: PVResultDto;
    fullRoofResult: PVResultDto;
    optimizer: Optimizer;
    fundingRate: number;
    baseUsageEstimated: boolean;
}

const initialState: State = {
    location: {
        address: 'Oberbilker Allee 244, 40227 Düsseldorf',
        lat: 51.2108431,
        lng: 6.802931999999999,
    },
    errorMessage: '',
    buildings: [],
    highlightedBuilding: '',
    isMapLoading: false,
    isCalculationLoading: false,
    showOnlySelectedBuildings: false,
    buildingsMetadata: [],
    selectedBuildingMetadata: null,
    isImportBuildingLoading: false,

    editedVariant: {
        pvAnalysisName: '',
        name: '',
        maxSystemCapacityAdjustment: 80,
        buildings: [],
        pvParams: DEFAULT_PV_PARAMS,
        calculatorResults: null,
    },
};

const mapToPvResultDto = (result: PvAnalysisVariationCalculatorResult): PVResultDto => {
    return {
        design_kwp: result.kwp,
        design_kwh_per_year_ac: result.annualProduction,
        battery_capacity: result.batteryCapacity,
        load_summary: result.loadSummary,
        initial_investment_incl_funding: result.initialInvestmentInclFunding,
        initial_investment: result.initialInvestment,
        balance_30_years: result.balanceAfter30Years,
        rate_of_return: result.rateOfReturn,
        profit_loss_accumulated: result.profitLossAccumulated,
        amortization_years: result.amortizationYears,
        co2_savings: result.co2Savings,
        trees: result.trees,
        ratio_own_use: result.ratioOwnUse,
    };
};

const pvAnalysisSlice = createSlice({
    name: 'pvAnalysis',
    initialState,
    reducers: {
        resetState: () => initialState,

        updatePvParams(state, action: PayloadAction<PVInputParams>) {
            state.editedVariant.pvParams = action.payload;
        },

        setLocation(state, action: PayloadAction<GeoLocation>) {
            state.location = action.payload;
        },

        loadRealEstateDataFailed(state, action: PayloadAction<string>) {
            state.isMapLoading = false;
            state.errorMessage = action.payload;
        },

        setHighlightedBuilding(state, action: PayloadAction<string>) {
            state.highlightedBuilding = action.payload;
        },

        addBuildingToSelection(state, action: PayloadAction<PvAnalysisVariationBuilding>) {
            state.editedVariant.buildings.push(action.payload);
        },

        removeBuildingFromSelection(state, action: PayloadAction<PvAnalysisVariationBuilding>) {
            const selectedBuilding = action.payload;

            state.editedVariant.buildings = state.editedVariant.buildings.filter(
                (building) => building.code !== selectedBuilding.code,
            );
        },

        removeRoofSegmentFromBuildingSelection(
            state,
            action: PayloadAction<{
                selectedBuilding: PvAnalysisVariationBuilding;
                roofSegment: PvAnalysisVariationRoofSegment;
            }>,
        ) {
            const { selectedBuilding, roofSegment } = action.payload;

            const updatedBuilding = selectedBuilding.roofSegments.filter(
                (r: PvAnalysisVariationRoofSegment) => r !== roofSegment,
            );
            state.editedVariant.buildings = state.editedVariant.buildings.map((building) => {
                if (building.code === selectedBuilding.code) {
                    return {
                        ...building,
                        roofSegments: updatedBuilding,
                    };
                }
                return building;
            });
        },

        resetBuildingSelection(state) {
            state.editedVariant.buildings = [];
        },

        updateSelectedBuilding(state, action: PayloadAction<PvAnalysisVariationBuilding>) {
            state.editedVariant.buildings = state.editedVariant.buildings.map((building) => {
                if (building.code === action.payload.code) {
                    return action.payload;
                }
                return building;
            });
        },

        toggleShowOnlySelectedBuildings(state) {
            console.log('TOGGLE_SHOW_ONLY_SELECTED_BUILDINGS: ' + state.showOnlySelectedBuildings);
            state.showOnlySelectedBuildings = !state.showOnlySelectedBuildings;
        },

        setMaxInstalationCapacity(state, action: PayloadAction<number>) {
            state.editedVariant.maxSystemCapacityAdjustment = action.payload;
            state.editedVariant.buildings = [];
        },

        setErrorMessage(state, action: PayloadAction<string>) {
            state.errorMessage = action.payload;
        },
        setSelectedBuildingMetadata(state, action: PayloadAction<PvAnalysisBuildingMetadata>) {
            state.selectedBuildingMetadata = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(loadPvAnalysisVariant.fulfilled, (state, action) => {
            const variant = action.payload;

            if (
                !variant.mapScreenshot ||
                !variant.calculatorParams ||
                !variant.calculatorOptimizedResult ||
                !variant.calculatorFullRoofResult
            ) {
                return initialState;
            }

            state.editedVariant = {
                pvAnalysisUuid: variant.pvAnalysisUuid,
                pvAnalysisName: variant.pvAnalysisName ?? '',
                name: variant.name,
                author: variant.author,
                maxSystemCapacityAdjustment: variant.maxSystemCapacityAdjustment,
                buildings: variant.buildings ?? [],
                pvParams: {
                    optimizer: variant.calculatorParams.optimizer,
                    buildingType: variant.calculatorParams.buildingType,
                    fundingRate: variant.calculatorParams.fundingRate,
                    loadProfiles: variant.calculatorParams.loadProfiles,
                    productionOptimizerMode: variant.calculatorParams.targetProduction
                        ? OptimizerMode.Manual
                        : OptimizerMode.Auto,
                    targetProduction: variant.calculatorParams.targetProduction ?? 0,
                    batteryOptimizerMode: variant.calculatorParams.batteryCapacityFactor
                        ? OptimizerMode.Manual
                        : OptimizerMode.Auto,
                    batteryKwpFactor: variant.calculatorParams.batteryCapacityFactor ?? 0,
                    constrainFullRoofTo100KWp: variant.calculatorParams.limitTo100Kwp,
                    chpProductionProfile: null, // TODO
                },
                calculatorResults: {
                    optimizedResult: mapToPvResultDto(variant.calculatorOptimizedResult),
                    fullRoofResult: mapToPvResultDto(variant.calculatorFullRoofResult),
                    optimizer: variant.calculatorParams.optimizer,
                    fundingRate: variant.calculatorParams.fundingRate,
                    baseUsageEstimated: admiPVCalculatorService.isBaseUsageEstimated(
                        variant.calculatorParams.loadProfiles,
                    ),
                },
            };

            state.location = {
                lng: variant.mapScreenshot.center[0],
                lat: variant.mapScreenshot.center[1],
                zoom: variant.mapScreenshot.zoom,
                address: '',
            };
        });

        builder.addCase(loadRealEstateData.pending, (state) => {
            state.isMapLoading = true;
        });
        builder.addCase(loadRealEstateData.fulfilled, (state, action) => {
            state.isMapLoading = false;
            state.buildings = action.payload;
        });
        builder.addCase(loadRealEstateData.rejected, (state, action) => {
            state.isMapLoading = false;
            state.errorMessage = action.error.message ?? action.error.code ?? 'Unknown error';
        });

        builder.addCase(loadPotentialBuildings.pending, (state) => {
            state.isMapLoading = true;
        });
        builder.addCase(loadPotentialBuildings.fulfilled, (state, action) => {
            state.isMapLoading = false;
            state.buildingsMetadata = action.payload;
        });
        builder.addCase(loadPotentialBuildings.rejected, (state, action) => {
            state.isMapLoading = false;
            state.errorMessage = action.error.message ?? action.error.code ?? 'Unknown error';
        });

        builder.addCase(loadBuildingToSelection.pending, (state) => {
            state.isMapLoading = true;
        });
        builder.addCase(loadBuildingToSelection.rejected, (state) => {
            state.isMapLoading = false;
        });
        builder.addCase(loadBuildingToSelection.fulfilled, (state, action) => {
            if (!state.editedVariant.buildings.some((building) => building.code === action.payload.code)) {
                state.editedVariant.buildings.push(action.payload);
            }
            state.isMapLoading = false;
        });

        builder.addCase(calculatePvResults.pending, (state) => {
            state.isCalculationLoading = true;
        });
        builder.addCase(calculatePvResults.fulfilled, (state, action) => {
            state.editedVariant.calculatorResults = action.payload;
            state.isCalculationLoading = false;
        });
        builder.addCase(calculatePvResults.rejected, (state, action) => {
            state.errorMessage = action.payload as string;
            state.editedVariant.calculatorResults = null;
            state.isCalculationLoading = false;
        });
        builder.addCase(importBuilding.pending, (state) => {
            state.isImportBuildingLoading = true;
        });
        builder.addCase(importBuilding.fulfilled, (state) => {
            state.isImportBuildingLoading = false;
        });
        builder.addCase(importBuilding.rejected, (state, action) => {
            state.errorMessage = admiPVCalculatorService.errorResponseToDescription(action.error);
            state.isImportBuildingLoading = false;
        });
    },
});

export const {
    resetState,
    updatePvParams,
    setLocation,
    setHighlightedBuilding,
    addBuildingToSelection,
    removeBuildingFromSelection,
    removeRoofSegmentFromBuildingSelection,
    resetBuildingSelection,
    updateSelectedBuilding,
    toggleShowOnlySelectedBuildings,
    setMaxInstalationCapacity,
    setErrorMessage,
    setSelectedBuildingMetadata,
} = pvAnalysisSlice.actions;
export default pvAnalysisSlice.reducer;
