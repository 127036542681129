/**
 * Downloads a file from a Blob object
 * @param blob Blob object to download
 * @param filename Target file name
 */
export function downloadFileFromBlob(blob: Blob, filename?: string) {
    const url = URL.createObjectURL(blob);
    downloadFileFromDataUrl(url, filename);
    URL.revokeObjectURL(url);
}

/**
 * Downloads a file from a data url
 * @param dataUrl Data url to download
 * @param filename Target file name
 */
export function downloadFileFromDataUrl(dataUrl: string, filename?: string) {
    const a = document.createElement('a');
    a.href = dataUrl;

    if (filename) {
        a.download = filename;
    }

    a.click();
}
