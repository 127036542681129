import React from 'react';

import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import TableChart from '@mui/icons-material/TableChart';
import SettingsSystemDaydreamIcon from '@mui/icons-material/SettingsSystemDaydream';
import SolarPowerIcon from '@mui/icons-material/SolarPower';
import { ElectricCar } from '@mui/icons-material';

import EmptyLayout from 'layouts/EmptyLayout';
import AppLayout from 'layouts/MainLayout';

import HomePage from 'features/home/HomePage';
import TaggingPage from 'features/tagging/TaggingPage';

import CreateTenderPage from 'features/tenders/CreateTenderPage';
import NotFoundPage from 'features/404/NotFoundPage';

import { PvSolVmPage } from 'features/pv-sol-vm/PvSolVmPage';

import GlobalTaggerPage from 'features/global-tagger/GlobalTaggerPage';
import OnsiteListPage from 'features/onsite/OnsiteListPage';
import OnsiteTenderFormPage from 'features/onsite/OnsiteTenderFormPage';
import PVCalculatorPage from 'features/pv-calculator/PVCalculatorPage';
import PVAnalysisListPage from 'features/pv-analysis/PvAnalysisListPage';
import PvAnalysisCreateEditPage from 'features/pv-analysis/PvAnalysisCreateEditPage';
import NewBuilding from 'features/pv-analysis/NewBuilding';
import { FeasibilityStudyCreatePage } from 'features/feasibility-study/FeasibilityStudyCreatePage';

export enum PAGETYPE {
    MAIN,
    FOOTER,
}

export const routesConfig = [
    {
        element: <AppLayout />,
        children: [
            {
                id: 'homepage',
                path: '/',
                handle: {
                    title: 'Home',
                    type: PAGETYPE.MAIN,
                    icon: <HomeRoundedIcon />,
                },
                children: [
                    {
                        id: 'homepage_index',
                        index: true,
                        element: <HomePage />,
                    },

                    {
                        id: 'dev',
                        path: '/dev',
                        handle: {
                            title: 'Development',
                            type: PAGETYPE.MAIN,
                        },
                        children: [
                            {
                                id: 'global-tagger',
                                path: '/dev/global-tagger',
                                element: <GlobalTaggerPage />,
                                handle: {
                                    title: 'Global Tagger',
                                },
                            },
                            {
                                id: 'pv-analysis-global',
                                path: '/dev/pv-analysis/global',
                                element: <PVAnalysisListPage />,
                                handle: {
                                    title: 'PV Analysis',
                                },
                            },
                            {
                                id: 'pv-analysis-global-create',
                                path: '/dev/pv-analysis/global/create',
                                element: <PvAnalysisCreateEditPage />,
                            },
                            {
                                id: 'pv-analysis-global-details',
                                path: '/dev/pv-analysis/global/pv-analysis-variation/:id/details',
                                element: <PvAnalysisCreateEditPage />,
                            },
                            {
                                id: 'pv-analysis-global-import-building',
                                path: '/dev/pv-analysis/global/import-building',
                                element: <NewBuilding />,
                            },
                            {
                                id: 'feasibility-study-create',
                                path: '/dev/feasibility-study/create',
                                element: <FeasibilityStudyCreatePage />,
                                handle: {
                                    title: 'Feasibility Study',
                                },
                            },
                        ],
                    },
                    {
                        id: 'pv-analysis',
                        path: '/pv-analysis',
                        handle: {
                            title: 'Potential Analysis',
                            type: PAGETYPE.MAIN,
                            icon: <SolarPowerIcon />,
                        },
                        children: [
                            {
                                id: 'tagger',
                                path: '/pv-analysis/nrw-tagger',
                                element: <TaggingPage />,
                                handle: {
                                    title: 'NRW Tagger',
                                },
                            },
                            {
                                id: 'pv-calculator',
                                path: '/pv-analysis/nrw-pv-calculator',
                                element: <PVCalculatorPage />,
                                handle: {
                                    title: 'NRW PV Calculator',
                                },
                            },
                        ],
                    },
                    {
                        id: 'onsite',
                        path: '/onsite',
                        handle: {
                            title: 'Onsite',
                            type: PAGETYPE.MAIN,
                            icon: <ElectricCar />,
                        },
                        children: [
                            {
                                id: 'onsite_visits',
                                path: '/onsite/visits',
                                element: <OnsiteListPage />,
                                handle: {
                                    title: 'Onsite Visits',
                                },
                            },
                            {
                                id: 'onsite_details',
                                path: '/onsite/:reference/tender-form',
                                element: <OnsiteTenderFormPage />,
                            },
                        ],
                    },
                    {
                        id: 'tender',
                        path: '/tender',
                        handle: {
                            title: 'Tender',
                            type: PAGETYPE.MAIN,
                            icon: <TableChart />,
                        },
                        children: [
                            {
                                id: 'create-boq',
                                path: '/tender/create-boq',
                                element: <CreateTenderPage />,
                                handle: {
                                    title: 'Create Bill of Quantities',
                                },
                            },
                        ],
                    },
                    {
                        id: 'pv-sol-vm',
                        path: '/pv-sol-vm',
                        element: <PvSolVmPage />,
                        handle: {
                            title: 'PV*SOL VM',
                            type: PAGETYPE.MAIN,
                            icon: <SettingsSystemDaydreamIcon />,
                        },
                    },
                ],
            },

            // footer
            // {
            //     id: "settings", path: "/settings/:settingsSubPage?", element: <SettingsPage />,
            //     handle: {
            //         defaultPath: "/settings/account",
            //         title: "Settings",
            //         type: PAGETYPE.FOOTER,
            //         icon: <SettingsRoundedIcon />,
            //     },
            // },
            // {
            //     id: "support", path: "/support", element: <SupportPage />,
            //     handle: {
            //         title: "Support",
            //         type: PAGETYPE.FOOTER,
            //         icon: <SupportRoundedIcon />,   // <QuestionAnswerRoundedIcon />
            //     },
            // },
        ],
    },

    {
        element: <EmptyLayout />,
        children: [
            {
                id: '404',
                path: '*',
                element: <NotFoundPage />,
                handle: {
                    title: '404 - Page not found',
                },
            },
        ],
    },
];
