import React, { useEffect, useRef, useState } from 'react';
import { CircularProgress, Grid } from '@mui/joy';
import { Position } from 'geojson';
import { coordEach, feature, flatten } from '@turf/turf';

import useGoogleMapsApiLoader from 'infrastructure/google/useGoogleMapsApiLoader';
import { generateColorHashFromString } from 'common/generateColorHashFromString';
import { PvAnalysisBuildingMetadata } from '../pv-analysis.dto';
import { setLocation, loadPotentialBuildings, setSelectedBuildingMetadata } from '../pv-analysis.slice';
import { useAppDispatch, useAppSelector } from 'redux-app-hooks';
import useDebouncer from 'common/useDebouncer';

export interface TakeScreenshotOptions {
    blob: Blob;
    center: Position;
    zoom: number;
}

export interface MapOptions {
    center: Position;
    zoom: number;
}

const LOAD_BUILDINGS_DEBOUNCE_MS = 2000;

export default function MetaDataMap() {
    const { location, buildingsMetadata, isMapLoading, selectedBuildingMetadata } = useAppSelector(
        (state) => state.pvAnalysis,
    );
    const dispatch = useAppDispatch();

    const [map, setMap] = useState<google.maps.Map | undefined>();
    const [marker, setMarker] = useState<google.maps.marker.AdvancedMarkerElement>();
    const [geocoder, setGeocoder] = useState<google.maps.Geocoder>();

    const [polygons, setPolygons] = useState<{
        [key: string]: google.maps.Polygon;
    }>({});

    const loader = useGoogleMapsApiLoader();

    const mapRef = useRef(null);
    const locationRef = useRef(location);

    const loadBuildingsDebouncer = useDebouncer();

    useEffect(() => {
        loadMap();
    }, []);

    useEffect(() => {
        console.debug('Map.location changed');
        refreshCenter();
        refreshPolygons();
        refreshSearchMarker();
    }, [location, buildingsMetadata]);

    useEffect(() => {
        if (map !== undefined && geocoder !== undefined) {
            google.maps.event.addListener(map, 'dragend', () => dragend(map, geocoder));
        }
    }, [map, geocoder]);

    useEffect(() => {
        locationRef.current = location;

        if (!location) {
            return;
        }

        loadBuildingsDebouncer(() => {
            console.debug('RealEstateData.location', location);
            dispatch(
                loadPotentialBuildings({
                    location,
                    radius: 100,
                    layers: [],
                    maxSystemCapacityAdjustment: 0,
                }),
            );
        }, LOAD_BUILDINGS_DEBOUNCE_MS);
    }, [location]);

    async function loadMap() {
        console.debug('loadMap');

        const mapOptions: google.maps.MapOptions = {
            mapId: 'admi-tagger',
            mapTypeId: 'satellite',
            mapTypeControl: false,
            tilt: 0,
            tiltInteractionEnabled: false,
            streetViewControl: false,
            fullscreenControl: false,
        };
        const mapElement = document.getElementById('map');
        if (mapElement !== null) {
            const { Map } = (await loader.importLibrary('maps')) as google.maps.MapsLibrary;

            // Since this is an async function, the location property might be a stale value
            // We therefore use a ref to access the latest version.
            if (locationRef.current) {
                mapOptions.center = { lat: locationRef.current.lat, lng: locationRef.current.lng };
                mapOptions.zoom = locationRef.current.zoom ?? 18;
            }

            setMap(new Map(mapElement, mapOptions));

            const { Geocoder } = (await loader.importLibrary('geocoding')) as google.maps.GeocodingLibrary;
            setGeocoder(new Geocoder());
        }
    }

    async function refreshPolygons() {
        console.debug('refreshPolygons');

        if (map !== undefined && buildingsMetadata !== undefined) {
            // Clear existing polygons
            console.log('buildingsMetadata', buildingsMetadata);
            Object.keys(polygons).forEach((key) => polygons[key].setMap(null));
            setPolygons({});

            // Iterate over all buildings
            buildingsMetadata.forEach((buildingMetadata) => {
                // Determine the color: selected buildings use selectedColor, others get unique colors
                const color = generateColorHashFromString(`building_${buildingMetadata.code}`);

                console.debug('building geometry', buildingMetadata.geometry);

                const buildingsPolygons = flatten(feature(buildingMetadata.geometry));
                buildingsPolygons.features.forEach((feature) => {
                    console.debug('feature', feature);

                    const polygonData: { lat: number; lng: number }[] = [];
                    coordEach(feature, (coord) => {
                        polygonData.push({ lat: coord[1], lng: coord[0] });
                    });

                    // Create a new polygon with the determined color
                    const polygon = new google.maps.Polygon({
                        paths: polygonData,
                        strokeColor: `#${color}`,
                        strokeOpacity: 1,
                        strokeWeight: 1,
                        fillColor: `#${color}`,
                        fillOpacity: 0.5,
                    });

                    // Attach event listeners for hover and click
                    attachPolygonInfoWindow(polygon, buildingMetadata);

                    google.maps.event.addListener(polygon, 'click', () => {
                        if (buildingMetadata.code !== selectedBuildingMetadata?.code) {
                            dispatch(setSelectedBuildingMetadata(buildingMetadata));
                        }
                        // Refresh polygons to update selected buildings' colors
                        refreshPolygons();
                    });

                    // Display the polygon on the map
                    polygon.setMap(map);

                    // Update state with the new polygon
                    const statePolygons = polygons;
                    statePolygons[buildingMetadata.code] = polygon;
                    setPolygons(statePolygons);
                });
            });
        }
    }

    async function dragend(map: google.maps.Map, geocoder: google.maps.Geocoder): Promise<void> {
        console.debug('dragend', map, geocoder);
        if (map !== undefined && geocoder !== undefined) {
            try {
                const center = map.getCenter();
                if (!center) {
                    throw new Error('Map center is not defined');
                }

                const geocoderResponse: google.maps.GeocoderResponse = await geocoder.geocode({
                    location: center,
                });
                console.debug('geocoderResponse', geocoderResponse);

                dispatch(
                    setLocation({
                        address: geocoderResponse.results[0].formatted_address,
                        lat: center.lat(),
                        lng: center.lng(),
                    }),
                );
            } catch (e) {
                console.error('Geocoder failed due to: ' + e);
            }
        }
    }

    function refreshCenter() {
        console.debug('refreshCenter', location);

        if (map !== undefined && location) {
            const centerLatLng = new google.maps.LatLng(location.lat, location.lng);
            map.setCenter(centerLatLng);

            if (location.zoom !== undefined) {
                map.setZoom(location.zoom);
            }
        }
    }

    async function refreshSearchMarker(): Promise<void> {
        console.debug('refreshSearchMarker', location);

        if (map !== undefined && location) {
            const { AdvancedMarkerElement } = (await loader.importLibrary('marker')) as google.maps.MarkerLibrary;
            if (marker !== undefined) {
                marker.map = null;
                setMarker(marker);
            }

            setMarker(
                new AdvancedMarkerElement({
                    position: { lat: location.lat, lng: location.lng },
                    map,
                    title: location.address + '\n' + location.lat + ',' + location.lng,
                }),
            );
        }
    }

    return (
        <Grid container className="map" justifyContent="center">
            <Grid style={{ height: '95%', width: '100%', position: 'relative' }}>
                <div ref={mapRef} id="map" style={{ height: '100%', width: '100%' }} />
                {isMapLoading && (
                    <div style={{ position: 'absolute', top: 8, left: 8 }}>
                        <CircularProgress />
                    </div>
                )}
            </Grid>
        </Grid>
    );

    function attachPolygonInfoWindow(polygon: google.maps.Polygon, buildingMetadata: PvAnalysisBuildingMetadata) {
        const infoWindow = new google.maps.InfoWindow();

        google.maps.event.addListener(polygon, 'click', (e: any) => {
            console.log(buildingMetadata);
            infoWindow.setContent(`
                <div>
                    <b>Building Function Code:</b> ${buildingMetadata.function}<br/>
                </div>
            `);
            const latLgn: google.maps.LatLng = e.latLng;
            let lat = e.latLng.lat();
            lat = lat + 0.0001; // I am doing this in order to show the infoWindow a little bit above the polygon
            infoWindow.setPosition(new google.maps.LatLng(lat, latLgn.lng()));
            infoWindow.open(map);
        });

        google.maps.event.addListener(polygon, 'mouseout', () => {
            infoWindow.close();
        });
    }
}
