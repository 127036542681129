import React, { useEffect, useRef, useState } from 'react';
import { Button, CircularProgress, Grid } from '@mui/joy';
import { Position } from 'geojson';
import { feature, flatten } from '@turf/turf';

import useGoogleMapsApiLoader from 'infrastructure/google/useGoogleMapsApiLoader';
import { generateColorHashFromString } from 'common/generateColorHashFromString';
import { PvAnalysisVariationBuilding } from '../pv-analysis.dto';
import {
    loadBuildingToSelection,
    loadRealEstateData,
    removeBuildingFromSelection,
    setHighlightedBuilding,
    setLocation,
} from '../pv-analysis.slice';
import Screenshot from './Screenshot';
import { useAppDispatch, useAppSelector } from 'redux-app-hooks';
import useDebouncer from 'common/useDebouncer';
import { Link } from 'react-router';
import { useTranslation } from 'react-i18next';
export interface TakeScreenshotOptions {
    blob: Blob;
    center: Position;
    zoom: number;
}

export interface MapOptions {
    center: Position;
    zoom: number;
}

interface Properties {
    takeScreenshot: (options: TakeScreenshotOptions) => void;
}

const LOAD_BUILDINGS_DEBOUNCE_MS = 2000;
//const { t } = useTranslation();
export default function Map({ takeScreenshot }: Properties) {
    const { location, buildings, editedVariant, highlightedBuilding, showOnlySelectedBuildings, isMapLoading } =
        useAppSelector((state) => state.pvAnalysis);
    const selectedBuildings = editedVariant.buildings;
    const dispatch = useAppDispatch();

    const [map, setMap] = useState<google.maps.Map | undefined>();
    const [marker, setMarker] = useState<google.maps.marker.AdvancedMarkerElement>();
    const [geocoder, setGeocoder] = useState<google.maps.Geocoder>();

    const [polygons, setPolygons] = useState<{
        [key: string]: google.maps.Polygon;
    }>({});

    const [screenshotSaved, setScreenshotSaved] = useState<boolean>(false);

    const loader = useGoogleMapsApiLoader();

    const mapRef = useRef(null);
    const locationRef = useRef(location);

    const loadBuildingsDebouncer = useDebouncer();
    const { t } = useTranslation();

    useEffect(() => {
        loadMap();
    }, []);

    useEffect(() => {
        console.debug('Map.location changed');
        refreshCenter();
        refreshPolygons();
        refreshSearchMarker();

        setScreenshotSaved(false);
    }, [location, buildings]);

    useEffect(() => {
        if (map !== undefined && geocoder !== undefined) {
            google.maps.event.addListener(map, 'dragend', () => dragend(map, geocoder));
        }
    }, [map, geocoder]);

    useEffect(() => {
        refreshPolygonsHighlight();
    }, [highlightedBuilding]);

    useEffect(() => {
        refreshPolygons();
    }, [selectedBuildings, showOnlySelectedBuildings]);

    useEffect(() => {
        locationRef.current = location;

        if (!location) {
            return;
        }

        loadBuildingsDebouncer(() => {
            console.debug('RealEstateData.location', location);
            dispatch(
                loadRealEstateData({
                    location,
                    radius: 1000,
                    layers: [],
                    maxSystemCapacityAdjustment: editedVariant.maxSystemCapacityAdjustment || 100,
                }),
            );
        }, LOAD_BUILDINGS_DEBOUNCE_MS);
    }, [location, editedVariant.maxSystemCapacityAdjustment]);

    async function loadMap() {
        console.debug('loadMap');

        const mapOptions: google.maps.MapOptions = {
            mapId: 'admi-tagger',
            mapTypeId: 'satellite',
            mapTypeControl: false,
            tilt: 0,
            tiltInteractionEnabled: false,
            streetViewControl: false,
            fullscreenControl: false,
        };
        const mapElement = document.getElementById('map');
        if (mapElement !== null) {
            const { Map } = (await loader.importLibrary('maps')) as google.maps.MapsLibrary;

            // Since this is an async function, the location property might be a stale value
            // We therefore use a ref to access the latest version.
            if (locationRef.current) {
                mapOptions.center = { lat: locationRef.current.lat, lng: locationRef.current.lng };
                mapOptions.zoom = locationRef.current.zoom ?? 18;
            }

            setMap(new Map(mapElement, mapOptions));

            const { Geocoder } = (await loader.importLibrary('geocoding')) as google.maps.GeocodingLibrary;
            setGeocoder(new Geocoder());
        }
    }

    async function refreshPolygons() {
        console.debug('refreshPolygons');

        if (map !== undefined && buildings !== undefined) {
            // Clear existing polygons
            Object.keys(polygons).forEach((key) => polygons[key].setMap(null));
            setPolygons({});

            // Define the color for selected buildings
            const selectedColor = 'f5cb5a';

            // Iterate over all buildings
            buildings.forEach((building) => {
                if (showOnlySelectedBuildings && !selectedBuildings.some((b) => b.code === building.code)) {
                    return;
                }
                // Determine the color: selected buildings use selectedColor, others get unique colors
                const color = selectedBuildings.some((b) => b.code === building.code)
                    ? selectedColor
                    : generateColorHashFromString(`building_${building.code}`);

                console.debug('building geometry', building.geometry);

                const buildingsPolygons = flatten(feature(building.geometry));
                buildingsPolygons.features.forEach((feature) => {
                    console.debug('feature', feature);
                    // Extract polygon paths from the feature's coordinates
                    const polygonPaths = feature.geometry.coordinates.map((ring: [number, number][]) =>
                        ring.map(([lng, lat]) => ({ lat, lng })) 
                    );
                    // Create a new polygon with the determined color
                    const polygon = new google.maps.Polygon({
                        paths: polygonPaths,
                        strokeColor: `#${color}`,
                        strokeOpacity: 1,
                        strokeWeight: 1,
                        fillColor: `#${color}`,
                        fillOpacity: highlightedBuilding === building.code ? 1 : 0.5,
                    });

                    // Attach event listeners for hover and click
                    attachPolygonInfoWindow(polygon, building);
                    google.maps.event.addListener(polygon, 'mouseover', () =>
                        dispatch(setHighlightedBuilding(building.code)),
                    );
                    google.maps.event.addListener(polygon, 'mouseout', () => dispatch(setHighlightedBuilding('')));
                    google.maps.event.addListener(polygon, 'click', () => {
                        console.debug('Clicked ' + building.code);
                        if (selectedBuildings.some((b) => b.code === building.code)) {
                            dispatch(removeBuildingFromSelection(building));
                        } else {
                            dispatch(
                                loadBuildingToSelection({
                                    uuid: building.code,
                                    maxSystemCapacityAdjustment: editedVariant.maxSystemCapacityAdjustment || 100,
                                }),
                            );
                        }
                        // Refresh polygons to update selected buildings' colors
                        refreshPolygons();
                    });

                    // Display the polygon on the map
                    polygon.setMap(map);

                    // Update state with the new polygon
                    const statePolygons = polygons;
                    statePolygons[building.code] = polygon;
                    setPolygons(statePolygons);
                });
            });
        }
    }

    async function dragend(map: google.maps.Map, geocoder: google.maps.Geocoder): Promise<void> {
        console.debug('dragend', map, geocoder);
        if (map !== undefined && geocoder !== undefined) {
            try {
                const center = map.getCenter();
                if (!center) {
                    throw new Error('Map center is not defined');
                }

                const geocoderResponse: google.maps.GeocoderResponse = await geocoder.geocode({
                    location: center,
                });
                console.debug('geocoderResponse', geocoderResponse);

                dispatch(
                    setLocation({
                        address: geocoderResponse.results[0].formatted_address,
                        lat: center.lat(),
                        lng: center.lng(),
                    }),
                );
            } catch (e) {
                console.error('Geocoder failed due to: ' + e);
            }
        }
    }

    function refreshCenter() {
        console.debug('refreshCenter', location);

        if (map !== undefined && location) {
            const centerLatLng = new google.maps.LatLng(location.lat, location.lng);
            map.setCenter(centerLatLng);

            if (location.zoom !== undefined) {
                map.setZoom(location.zoom);
            }
        }
    }

    async function refreshSearchMarker(): Promise<void> {
        console.debug('refreshSearchMarker', location);

        if (map !== undefined && location) {
            const { AdvancedMarkerElement } = (await loader.importLibrary('marker')) as google.maps.MarkerLibrary;
            if (marker !== undefined) {
                marker.map = null;
                setMarker(marker);
            }

            setMarker(
                new AdvancedMarkerElement({
                    position: { lat: location.lat, lng: location.lng },
                    map,
                    title: location.address + '\n' + location.lat + ',' + location.lng,
                }),
            );
        }
    }

    function refreshPolygonsHighlight(): void {
        console.debug('refreshPolygonsHighlight');

        if (map !== undefined && polygons !== undefined) {
            Object.keys(polygons).forEach((key) => {
                polygons[key].setOptions({
                    fillOpacity: key == highlightedBuilding ? 1 : 0.5,
                });
            });
        }
    }

    function takeScreenshotWithMapProps(blob: Blob) {
        const mapCenter = map?.getCenter();
        const mapZoom = map?.getZoom();
        if (mapCenter?.lng() !== undefined && mapCenter?.lat() !== undefined && mapZoom !== undefined) {
            takeScreenshot({ blob, center: [mapCenter.lng(), mapCenter.lat()], zoom: mapZoom });
            setScreenshotSaved(true);
        }
    }

    return (
        <Grid xs={8} container sx={{ height: '70vh' }}>
            <Grid container direction="row" spacing={2}>
                <Grid sx={{ flex: 'auto' }}>
                    <Screenshot
                        mapRef={mapRef}
                        disabled={screenshotSaved}
                        takeScreenshot={takeScreenshotWithMapProps}
                    />
                </Grid>
                <Grid sx={{ flex: 'auto', textAlign: 'right' }}>
                    <Link to="/dev/pv-analysis/global/import-building">
                        <Button variant="solid">{t('Import Building')}</Button>
                    </Link>
                </Grid>
            </Grid>
            <Grid style={{ height: '95%', width: '100%', position: 'relative' }}>
                <div ref={mapRef} id="map" style={{ height: '100%', width: '100%' }} />
                {isMapLoading && (
                    <div style={{ position: 'absolute', top: 8, left: 8 }}>
                        <CircularProgress />
                    </div>
                )}
            </Grid>
        </Grid>
    );

    function attachPolygonInfoWindow(polygon: google.maps.Polygon, building: PvAnalysisVariationBuilding) {
        const infoWindow = new google.maps.InfoWindow();

        google.maps.event.addListener(polygon, 'mouseover', (e: any) => {
            console.log(building);
            infoWindow.setContent(`<b>Building Function Code:</b> ${building.buildingFunctionCode}<br/>`);
            const latLgn: google.maps.LatLng = e.latLng;
            let lat = e.latLng.lat();
            lat = lat + 0.0001; // I am doing this in order to show the infoWindow a little bit above the polygon
            infoWindow.setPosition(new google.maps.LatLng(lat, latLgn.lng()));
            infoWindow.open(map);
        });

        google.maps.event.addListener(polygon, 'mouseout', () => {
            infoWindow.close();
        });
    }
}
