import React, { useEffect } from 'react';
import { FormControl, Input, FormLabel } from '@mui/joy';
import { parse } from 'csv-parse/browser/esm/sync';
import { useTranslation } from 'react-i18next';

export default function CHPProducer(props: { onChange: (values: number[] | null) => void }) {
    const { t } = useTranslation();

    const [profileValues, setProfileValues] = React.useState<number[] | null>(null);

    useEffect(() => props.onChange(profileValues), [profileValues]);

    const parseCsv = async (file?: File) => {
        if (!file) {
            setProfileValues(null);
            return;
        }

        const data = parse(await file.text(), { from: 2, cast: (value) => parseFloat(value) });
        setProfileValues(data.map((row: any) => row[0]));
    };

    return (
        <FormControl>
            <FormLabel>{t('CHP producer (cascade where PV is preferred)')}</FormLabel>
            <Input
                type="file"
                slotProps={{ input: { accept: '.csv' } }}
                onChange={(e) => e.target.files !== null && parseCsv(e.target.files[0])}
            />
        </FormControl>
    );
}
