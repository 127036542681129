import { CreateFeasibilityStudy } from './feasibility-study.dto';

export const INITIAL_VALUES: CreateFeasibilityStudy = {
    project: {
        municipalityName: '',
        name: '',
        address: '',
        imageGoogleMapsScreenshot: '',
    },
    contact: {
        name: '',
        address: '',
        email: '',
        phone: '',
        jobTitle: '',
    },
    potentialAnalysisResult: {
        buildingType: '',
        consumptionKwhPerYear: 0,
        pvKwp: 0,
        batteryKwh: 0,
        productionKwhPerYear: 0,
        co2Savings: 0,
        initialInvestment: 0,
        balance30Years: 0,
        rateOfReturn: 0,
        amortizationYears: 0,
        selfConsumptionRate: 0,
    },
    onsite: {
        authorName: '',
        onsiteDate: new Date(),
        accessToUtilityRoom: false,
        accessToMeterCabinet: false,
        accessToAttic: false,
        accessToRoof: false,
    },
    roof: {
        roofType: '',
        roofCovering: '',
        numberOfSelectedRoofSegments: 0,
        imageDroneRoof: '',
        roofTypeSpecific: {
            roofTiltIsSmallEnough: false,
            atticHeight: 0,
        },
    },
    imagePossibleModuleAssignment: '',
    fulfillsStaticRequirements: false,
    cableRouting: {
        hasSuitableCableRouting: false,
        imageCableRouting1: '',
        imageCableRouting2: '',
    },
    electricInstallation: {
        hasSuitableConnectionPoint: false,
        connectionPointType: '',
        imageConnectionPoint: '',
        hasSuitableInstallationZone: false,
        installationRoom: '',
        imageInstallationZoneComponents1: '',
        imageInstallationZoneComponents2: '',
    },
    fireProtection: {
        hasSuitableFireProtectionCableRouting: false,
        imageAreasWithFireProtectionMeasures: '',
        hasFireProtectionInstallationZone: false,
    },
};

const EXAMPLE_IMAGE_DATA_URL =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAHElEQVQI12P4//8/w38GIAXDIBKE0DHxgljNBAAO9TXL0Y4OHwAAAABJRU5ErkJggg==';
export const DUMMY_VALUES: CreateFeasibilityStudy = {
    project: {
        municipalityName: 'Musterstadt',
        name: 'Sample Project',
        address: 'Hauptstraße 1, 12345 Musterstadt',
        imageGoogleMapsScreenshot: EXAMPLE_IMAGE_DATA_URL,
    },
    contact: {
        name: 'John Doe',
        address: 'Hauptstraße 2, 12345 Musterstadt',
        email: 'john.doe@example.com',
        phone: '+49 1234 56789',
        jobTitle: 'Klimaschutzmanager',
    },
    potentialAnalysisResult: {
        buildingType: 'SCHULE',
        consumptionKwhPerYear: 12000,
        pvKwp: 10,
        batteryKwh: 5,
        productionKwhPerYear: 11000,
        co2Savings: 5000,
        initialInvestment: 15000,
        balance30Years: 20000,
        rateOfReturn: 5,
        amortizationYears: 10,
        selfConsumptionRate: 80,
    },
    onsite: {
        authorName: 'Jane Smith',
        onsiteDate: new Date(),
        accessToUtilityRoom: true,
        accessToMeterCabinet: true,
        accessToAttic: true,
        accessToRoof: true,
    },
    roof: {
        roofType: 'Flachdach mit Attika',
        roofCovering: 'Gründach',
        numberOfSelectedRoofSegments: 2,
        imageDroneRoof: EXAMPLE_IMAGE_DATA_URL,
        roofTypeSpecific: {
            roofTiltIsSmallEnough: true,
            atticHeight: 2.5,
        },
    },
    imagePossibleModuleAssignment: EXAMPLE_IMAGE_DATA_URL,
    fulfillsStaticRequirements: true,
    cableRouting: {
        hasSuitableCableRouting: true,
        imageCableRouting1: EXAMPLE_IMAGE_DATA_URL,
        imageCableRouting2: EXAMPLE_IMAGE_DATA_URL,
    },
    electricInstallation: {
        hasSuitableConnectionPoint: true,
        connectionPointType: 'Unterverteilung',
        imageConnectionPoint: EXAMPLE_IMAGE_DATA_URL,
        hasSuitableInstallationZone: true,
        installationRoom: 'Keller im EG',
        imageInstallationZoneComponents1: EXAMPLE_IMAGE_DATA_URL,
        imageInstallationZoneComponents2: EXAMPLE_IMAGE_DATA_URL,
    },
    fireProtection: {
        hasSuitableFireProtectionCableRouting: true,
        imageAreasWithFireProtectionMeasures: EXAMPLE_IMAGE_DATA_URL,
        hasFireProtectionInstallationZone: true,
    },
};
