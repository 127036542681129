import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { LocationSearch, GeoLocation, RealEstate, Building } from '../global-tagger.dto';
import { loadRealEstateData } from '../global-tagger.actions';
import { CircularProgress } from '@mui/joy';

interface Properties {
    location: GeoLocation;
    realEstate: RealEstate;
    isLoading: boolean;
    selectedBuildings: Building[];
    loadRealEstateData: any;
}

function RealEstateDetails(props: Properties) {
    const { location, realEstate, isLoading, selectedBuildings, loadRealEstateData } = props;

    useEffect(() => {
        console.debug('RealEstateData');
    }, []);

    useEffect(() => {
        if (realEstate !== undefined) {
            let ps = realEstate.buildings;
            if (selectedBuildings.length !== 0 && ps.length > 0) {
                ps = ps.filter((building) => selectedBuildings.some((b: Building) => b.code === building.code));
            }
        }
    }, [realEstate, selectedBuildings]);

    useEffect(() => {
        console.debug('RealEstateData.location', location);
        loadRealEstateData({
            location,
            radius: 1000,
            layers: [],
        });
    }, [location]);

    return <Fragment>{isLoading ? <CircularProgress /> : <></>}</Fragment>;
}

const mapStateToProps = function (state: any) {
    return {
        location: state.tagger.location,
        realEstate: state.tagger.realEstate,
        isLoading: state.tagger.isLoading,
        selectedBuildings: state.tagger.selectedBuildings,
    };
};

const mapDispatchToProps = function (dispatch: any) {
    return {
        loadRealEstateData: (dataSearch: LocationSearch) => dispatch(loadRealEstateData(dataSearch)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RealEstateDetails);
