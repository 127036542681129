import React, { useRef } from 'react';
import { useEffect, useState } from 'react';
import useGoogleMapsApiLoader from '../../../infrastructure/google/useGoogleMapsApiLoader';
import { connect } from 'react-redux';
import { Location, Row, RowConfig } from '../tagging.dto';
import { changeRowHighlight, setLocation, setSelectedTag } from '../tagging.actions';
import { Grid } from '@mui/joy';
import Screenshot from './Screenshot';

interface Properties {
    location: Location;
    rows: Row[];
    rowConfig: RowConfig[];
    selectedTag: string;
    setLocation: any;
    setSelectedTag: any;
    changeRowHighlight: any;
}

function Map(props: Properties) {
    const { location, rows, rowConfig, selectedTag, setLocation, setSelectedTag, changeRowHighlight } = props;

    const [map, setMap] = useState<google.maps.Map | undefined>();
    const [polygons, setPolygons] = useState<google.maps.Polygon[]>([]);
    const [marker, setMarker] = useState<google.maps.marker.AdvancedMarkerElement>();
    const [geocoder, setGeocoder] = useState<google.maps.Geocoder>();

    const loader = useGoogleMapsApiLoader();

    const mapRef = useRef(null);

    useEffect(() => {
        console.debug('Map');
        loadMap();
    }, []);

    useEffect(() => {
        console.debug('Map.location changed');
        refreshCenter();
        refreshPolygons();
        refreshSearchMarker();
    }, [location, rows]);

    useEffect(() => {
        refreshPolygonsHighlight();
    }, [rowConfig]);

    useEffect(() => {
        if (map !== undefined && geocoder !== undefined) {
            google.maps.event.addListener(map, 'dragend', () => dragend(map, geocoder));
        }
    }, [map, geocoder]);

    useEffect(() => {
        refreshPolygons();
    }, [selectedTag]);

    async function loadMap() {
        console.debug('loadMap');

        const mapOptions = {
            zoom: 18,
            center: { lat: location.lat, lng: location.lng },
            mapId: 'admi-tagger',
            mapTypeId: 'satellite',
        };
        const mapElement = document.getElementById('map');
        if (mapElement !== null) {
            const { Map } = (await loader.importLibrary('maps')) as google.maps.MapsLibrary;
            setMap(new Map(mapElement, mapOptions));

            const { Geocoder } = (await loader.importLibrary('geocoding')) as google.maps.GeocodingLibrary;
            setGeocoder(new Geocoder());
        }
    }

    async function refreshPolygons() {
        console.debug('refreshPolygons');
        if (map !== undefined && rows !== undefined) {
            polygons.forEach((feature) => feature.setMap(null));
            setPolygons([]);

            rows.forEach((row: any, index: number) => {
                let color = row.color;
                if (selectedTag !== '' && row.data.tag === selectedTag) {
                    color = 'f5cb5a';
                } else if (selectedTag !== '' && row.data.tag !== selectedTag) {
                    return;
                }

                row.data.geometry.coordinates.forEach((polygone: any) => {
                    const polygonData = polygone[0].map((lngLatPair: any[]) => ({
                        lng: lngLatPair[0],
                        lat: lngLatPair[1],
                    }));
                    const polygon: google.maps.Polygon = new google.maps.Polygon({
                        paths: polygonData,
                        strokeColor: '#' + color,
                        strokeOpacity: 1,
                        strokeWeight: 1,
                        fillColor: '#' + color,
                        fillOpacity: rowConfig[index] && rowConfig[index].isHighlighted ? 1 : 0.5,
                    });

                    attachPolygonInfoWindow(polygon, row.data);

                    google.maps.event.addListener(polygon, 'mouseover', () => changeRowHighlight(index, true));
                    google.maps.event.addListener(polygon, 'mouseout', () => changeRowHighlight(index, false));
                    google.maps.event.addListener(polygon, 'click', () => {
                        setSelectedTag(row.data.tag && row.data.tag !== selectedTag ? row.data.tag : '');
                    });

                    polygon.setMap(map);
                    const statePolygons = polygons;
                    statePolygons[index] = polygon;
                    setPolygons(statePolygons);
                });
            });
        }
    }

    async function dragend(map: google.maps.Map, geocoder: google.maps.Geocoder): Promise<void> {
        console.debug('dragend', map, geocoder);
        setSelectedTag('');
        if (map !== undefined && geocoder !== undefined) {
            try {
                const geocoderResponse: google.maps.GeocoderResponse = await geocoder.geocode({
                    location: map.getCenter(),
                });
                setLocation({
                    address: geocoderResponse.results[0].formatted_address,
                    lat: map.getCenter()?.lat(),
                    lng: map.getCenter()?.lng(),
                });
            } catch (e) {
                console.error('Geocoder failed due to: ' + e);
            }
        }
    }

    async function refreshSearchMarker(): Promise<void> {
        console.debug('refreshSearchMarker', location);

        if (map !== undefined) {
            const { AdvancedMarkerElement } = (await loader.importLibrary('marker')) as google.maps.MarkerLibrary;
            if (marker !== undefined) {
                marker.map = null;
                setMarker(marker);
            }

            setMarker(
                new AdvancedMarkerElement({
                    position: { lat: location.lat, lng: location.lng },
                    map,
                    title: location.address + '\n' + location.lat + ',' + location.lng,
                }),
            );
        }
    }

    async function refreshCenter(): Promise<void> {
        console.debug('refreshCenter', location);

        if (map !== undefined) {
            const centerLatLng = new google.maps.LatLng(location.lat, location.lng);
            map.setCenter(centerLatLng);
        }
    }

    /**
     * If mouse hover is slow, this feature might be improved
     */
    function refreshPolygonsHighlight(): void {
        console.debug('refreshPolygonsHighlight');

        if (map !== undefined && polygons !== undefined) {
            polygons.forEach((polygon, index) => {
                polygon.setOptions({
                    fillOpacity: rowConfig[index] && rowConfig[index].isHighlighted ? 1 : 0.5,
                });
            });
        }
    }

    function attachPolygonInfoWindow(polygon: google.maps.Polygon, building: any) {
        const infoWindow = new google.maps.InfoWindow();

        google.maps.event.addListener(polygon, 'mouseover', (e: any) => {
            const kwp = (Number(building.kw_17) * 430) / 280 + Number(building.kw_21_7);
            infoWindow.setContent(
                `<b>Building Function:</b> ${building.OS_description}<br/>` +
                    `<b>Roof Area:</b> ${building.modarea} m²<br/>` +
                    `<b>Potential:</b> ${kwp.toFixed(2)} kWp<br/>` +
                    `<b>Efficiency:</b> ${building.kwh_kwp} kWh/kWp<br/>`,
            );
            const latLgn: google.maps.LatLng = e.latLng;
            let lat = e.latLng.lat();
            lat = lat + 0.0001; // I am doing this in order to show the infoWindow a little bit above the polygon
            infoWindow.setPosition(new google.maps.LatLng(lat, latLgn.lng()));
            infoWindow.open(map);
        });

        google.maps.event.addListener(polygon, 'mouseout', () => {
            infoWindow.close();
        });
    }

    return (
        <Grid xs={12} container className="map" justifyContent="center">
            <Grid style={{ height: '5%', width: '100%' }}>
                <Screenshot mapRef={mapRef} />
            </Grid>
            <Grid style={{ height: '95%', width: '100%' }}>
                <div ref={mapRef} id="map" style={{ height: '100%', width: '100%' }} />
            </Grid>
        </Grid>
    );
}

const mapStateToProps = function (state: any) {
    return {
        location: state.tagging.location,
        rows: state.tagging.rows,
        rowConfig: state.tagging.rowConfig,
        selectedTag: state.tagging.selectedTag,
    };
};

const mapDispatchToProps = function (dispatch: any) {
    return {
        setLocation: (location: Location) => dispatch(setLocation(location)),
        setSelectedTag: (selectedTag: string) => dispatch(setSelectedTag(selectedTag)),
        changeRowHighlight: (index: number, isHighlighted: boolean) =>
            dispatch(changeRowHighlight(index, isHighlighted)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Map);
