import React, { useEffect, useState } from 'react';
import { FormControl, FormLabel, Typography, Input, Select, Option, Box } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { formFieldBuilder, handleSelect } from 'common/formikUtils';
import ImageSelector from 'common/components/ImageSelector';
import { ifNotNull } from 'common/helpers';

import { STOERFLAECHEN } from '../onsite/selectOptions';
import { OnsiteDataPoint } from 'features/onsite/onsite.dto';

interface Properties {
    formData: { [reference: string]: OnsiteDataPoint };
    formik: any;
    index: number;
}

function FragmentDachflaeche(props: Properties) {
    const { formData, formik, index } = props;

    const formField = (name: string) => formFieldBuilder(formik, name);

    const fieldPrefix = `DACHFLAECHE[${index}]`;

    const { t } = useTranslation();

    const [frontView, setFrontView] = useState<string[]>([]);

    useEffect(() => {
        ifNotNull(formData['roof-pitch'], (value) =>
            formik.setFieldValue(`${fieldPrefix}.DACHNEIGUNG`, Number(value['response'])),
        );
        ifNotNull(formData['eaves-height'], (value) =>
            formik.setFieldValue(`${fieldPrefix}.TRAUFHOEHE`, Number(value['response'])),
        );

        ifNotNull(formData['front-view'], (value) => {
            setFrontView(value['photos'].map((photo: any) => photo.url));
        });
    }, [formData]);

    return (
        <>
            <Typography level="h3">Dachfläche {index + 1}</Typography>

            <Box sx={{ display: 'flex', gap: 1 }}>
                <FormControl required>
                    <FormLabel>Ausrichtung (Himmelsrichtung)</FormLabel>
                    <Input
                        type="text"
                        placeholder="Ausrichtung (Himmelsrichtung)"
                        {...formField(`${fieldPrefix}.AUSRICHTUNG`)}
                    />
                </FormControl>

                <FormControl required>
                    <FormLabel>Potenzial (in m²)</FormLabel>
                    <Input type="number" placeholder="Potenzial (in m²)" {...formField(`${fieldPrefix}.POTENZIAL`)} />
                </FormControl>
            </Box>
            <FormControl required>
                <FormLabel>Dachneigung (in Grad)</FormLabel>
                <Input type="number" placeholder="Dachneigung (in Grad)" {...formField(`${fieldPrefix}.DACHNEIGUNG`)} />
            </FormControl>

            <FormControl required>
                <FormLabel>Traufhöhe (in m)</FormLabel>
                <Input type="number" placeholder="Traufhöhe (in m)" {...formField(`${fieldPrefix}.TRAUFHOEHE`)} />
            </FormControl>
            <FormControl required>
                <FormLabel>Trauflänge (in m)</FormLabel>
                <Input type="number" placeholder="Trauflänge (in m)" {...formField(`${fieldPrefix}.TRAUFLAENGE`)} />
            </FormControl>
            <FormControl>
                <FormLabel>Störflächen</FormLabel>
                <Select
                    multiple
                    sx={{ width: '40rem' }}
                    value={formik.values.roof_type}
                    onChange={handleSelect(formik, `${fieldPrefix}.LIST_STOERF`)}
                >
                    {STOERFLAECHEN.map((stoerflaeche) => (
                        <Option key={stoerflaeche} value={stoerflaeche}>
                            {stoerflaeche}
                        </Option>
                    ))}
                </Select>
            </FormControl>

            <FormControl required>
                <FormLabel>Drohnenaufnahme Dachfläche</FormLabel>
                <ImageSelector name={`${fieldPrefix}.AUFNAHME`} formik={formik} required={true} />
            </FormControl>

            <FormControl>
                <FormLabel>{t('Possible Module Assignment')}</FormLabel>
                <ImageSelector name={`${fieldPrefix}.POSSIBLE_MODULE_ASSIGNMENT`} formik={formik} />
            </FormControl>

            <FormControl>
                <FormLabel>Aufnahme Frontansicht Dachseite</FormLabel>
                <ImageSelector name={`${fieldPrefix}.AUFNAHME_FRONT`} formik={formik} imageUrls={frontView} />
            </FormControl>
        </>
    );
}

const mapStateToProps = function (state: any) {
    return {
        formData: state.onsite.formData,
    };
};

export default connect(mapStateToProps)(FragmentDachflaeche);
