import { Dispatch } from 'redux';
import { LocationSearch, GeoLocation, RealEstate, Building, BuildingSearch } from './global-tagger.dto';
import { admiGlobalTaggerService } from './admi-global-tagger.service';

export const TYPES = {
    SET_LOCATION: 'SET_LOCATION',

    LOAD_REAL_ESTATE_DATA: 'LOAD_REAL_ESTATE_DATA',

    LOAD_REAL_ESTATE_DATA_REQUESTED: 'LOAD_REAL_ESTATE_DATA_REQUESTED',
    LOAD_REAL_ESTATE_DATA_SUCCEEDED: 'LOAD_REAL_ESTATE_DATA_SUCCEEDED',
    LOAD_REAL_ESTATE_DATA_FAILED: 'LOAD_REAL_ESTATE_DATA_FAILED',

    SET_SELECTED_PLOT: 'SET_SELECTED_PLOT',

    SET_HIGHLIGHTED_BUILDING: 'SET_HIGHLIGHTED_BUILDING',

    SET_SHOW_ROOF_SEGMENTS: 'SET_SHOW_ROOF_SEGMENTS',

    SET_ERROR_MESSAGE: 'SET_ERROR_MESSAGE',

    ADD_BUILDING_TO_SELECTION: 'ADD_BUILDING_TO_SELECTION',

    LOAD_BUILDING_TO_SELECTION_REQUESTED: 'ADD_BUILDING_TO_SELECTION_REQUESTED',
    LOAD_BUILDING_TO_SELECTION_SUCCEEDED: 'ADD_BUILDING_TO_SELECTION_SUCCEEDED',
    LOAD_BUILDING_TO_SELECTION_FAILED: 'ADD_BUILDING_TO_SELECTION_FAILED',

    REMOVE_BUILDING_FROM_SELECTION: 'REMOVE_BUILDING_FROM_SELECTION',
    RESET_BUIDING_SELECTION: 'RESET_BUIDING_SELECTION',
    TOGGLE_SHOW_ONLY_SELECTED_BUILDINGS: 'TOGGLE_SHOW_ONLY_SELECTED_BUILDINGS',
};

export const loadRealEstateData = (dataSearch: LocationSearch) => async (dispatch: Dispatch) => {
    dispatch(loadRealEstateDataRequested(dataSearch));
    console.debug('loadRealEstateDataRequested.dataSearch', dataSearch);

    try {
        const realEstate = await admiGlobalTaggerService.loadRealEstateData(dataSearch);
        dispatch(loadRealEstateDataSucceeded(dataSearch, realEstate));
    } catch (e) {
        let errorMessage = '';
        if (typeof e === 'string') {
            errorMessage = e;
        } else if (e instanceof Error) {
            errorMessage = e.message;
        }
        console.error('loadRealEstateDataFailed.error', e, errorMessage);

        dispatch(loadRealEstateDataFailed(dataSearch, errorMessage));
    }
};

export function loadRealEstateDataRequested(dataSearch: LocationSearch) {
    return { type: TYPES.LOAD_REAL_ESTATE_DATA_REQUESTED, dataSearch };
}

export function loadRealEstateDataSucceeded(dataSearch: LocationSearch, realEstate: RealEstate) {
    return {
        type: TYPES.LOAD_REAL_ESTATE_DATA_SUCCEEDED,
        dataSearch,
        realEstate,
    };
}

export function loadRealEstateDataFailed(dataSearch: LocationSearch, errorMessage: string) {
    return { type: TYPES.LOAD_REAL_ESTATE_DATA_FAILED, dataSearch, errorMessage };
}

export function setLocation(location: GeoLocation) {
    return { type: TYPES.SET_LOCATION, location };
}

export function setselectedBuilding(selectedBuilding: string) {
    return { type: TYPES.SET_SELECTED_PLOT, selectedBuilding };
}

export const loadBuildingToSelection = (search: BuildingSearch) => async (dispatch: Dispatch) => {
    dispatch(loadBuildingToSelectionRequested());
    try {
        const building = await admiGlobalTaggerService.loadBuildingData(search);
        dispatch(loadBuildingToSelectionSucceeded(building));
    } catch (e) {
        let errorMessage = '';
        if (typeof e === 'string') {
            errorMessage = e;
        } else if (e instanceof Error) {
            errorMessage = e.message;
        }
        console.error('loadBuildingDataFailed.error', e, errorMessage);

        dispatch(setErrorMessage(errorMessage));
    }
};

export function loadBuildingToSelectionRequested() {
    return { type: TYPES.LOAD_BUILDING_TO_SELECTION_REQUESTED };
}

export function loadBuildingToSelectionSucceeded(selectedBuilding: Building) {
    return { type: TYPES.LOAD_BUILDING_TO_SELECTION_SUCCEEDED, selectedBuilding };
}

export function loadBuildingToSelectionFailed(errorMessage: string) {
    return { type: TYPES.LOAD_BUILDING_TO_SELECTION_FAILED, errorMessage };
}

export function addBuildingToSelection(selectedBuilding: Building) {
    return { type: TYPES.ADD_BUILDING_TO_SELECTION, selectedBuilding };
}

export function removeBuildingFromSelection(selectedBuilding: Building) {
    return { type: TYPES.REMOVE_BUILDING_FROM_SELECTION, selectedBuilding };
}

export function resetBuildingSelection() {
    return { type: TYPES.RESET_BUIDING_SELECTION };
}

export function setHighlightedBuilding(highlightedBuilding: string) {
    console.log(highlightedBuilding);
    return { type: TYPES.SET_HIGHLIGHTED_BUILDING, highlightedBuilding };
}

export function setShowRoofSegments(showRoofSegments: boolean) {
    return { type: TYPES.SET_SHOW_ROOF_SEGMENTS, showRoofSegments };
}

export function setErrorMessage(errorMessage: string) {
    return { type: TYPES.SET_ERROR_MESSAGE, errorMessage };
}

export function toggleShowOnlySelectedBuildings() {
    console.log('TOGGLE_SHOW_ONLY_SELECTED_BUILDINGS');
    return { type: TYPES.TOGGLE_SHOW_ONLY_SELECTED_BUILDINGS };
}
