import React, { Fragment, MouseEvent, MutableRefObject, useEffect, useState } from 'react';
import Button from '@mui/joy/Button';
import { connect } from 'react-redux';
import { Photo } from '@mui/icons-material';
import { CircularProgress, Tooltip } from '@mui/joy';
import { admiRealEstateService } from '../../real-estate/admi-real-estate.service';
import useDOMRendererScreenshot from '../../../infrastructure/image/useDOMRendererScreenshot';
import { useTranslation } from 'react-i18next';
import Modal from '@mui/joy/Modal';
import Sheet from '@mui/joy/Sheet';
import ModalClose from '@mui/joy/ModalClose';
import Typography from '@mui/joy/Typography';
import { setErrorMessage, toggleShowOnlySelectedBuildings } from '../global-tagger.actions';
import { Building } from '../global-tagger.dto';
import { hashStringArray } from '../../../common/hashUtils';

interface Properties {
    mapRef: MutableRefObject<any>;
    selectedBuildings: Building[];
    setErrorMessage: any;
    toggleShowOnlySelectedBuildings: any;
}

function Screenshot(props: Properties) {
    const { mapRef, selectedBuildings, setErrorMessage, toggleShowOnlySelectedBuildings } = props;

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [image, setImage] = useState<string>('');

    const [isPreviewModalOpen, setIsPreviewModalOpen] = useState<boolean>(false);

    const screenshot = useDOMRendererScreenshot();

    const { t } = useTranslation();

    useEffect(() => {
        if (image) {
            setIsPreviewModalOpen(true);
        }
    }, [image]);

    async function savePhoto(event: MouseEvent<HTMLButtonElement>) {
        event.preventDefault();
        setErrorMessage('');
        setIsLoading(true);
        setImage('');

        try {
            if (mapRef) {
                toggleShowOnlySelectedBuildings();
                const position = mapRef.current.getBoundingClientRect();
                console.log('position', position);
                // Give the map time to update before capturing the screenshot
                setTimeout(async () => {
                    const canvas = await screenshot.screenshot(mapRef.current, {
                        top: 0,
                        left: 80,
                        right: 80,
                        bottom: 0,
                        useCORS: true,
                    });
                    setImage(canvas.toDataURL('image/png'));
                    canvas.toBlob(async (blob: Blob | null) => {
                        try {
                            if (!blob) {
                                console.error('Could not create a blob from the canvas');
                                return;
                            }
                            //TODO review image upload api
                            const selectionHash = hashStringArray(selectedBuildings.map((building) => building.code));
                            console.log('selectionHash : ' + selectionHash);
                            await admiRealEstateService.uploadPlotImage(selectionHash, blob);
                        } catch (e) {
                            console.error('Screenshot', 'Could not upload the screenshot', e);
                            setErrorMessage(`Das Bild konnte nicht gespeichert werden: ${e}`);
                        }
                        setIsLoading(false);
                    }, 'image/png');
                    toggleShowOnlySelectedBuildings();
                }, 100);
            }
        } catch (e) {
            toggleShowOnlySelectedBuildings();
            console.warn('Screenshot', 'Could not save the screenshot', e);
            setErrorMessage(`Das Bild konnte nicht gespeichert werden: ${e}`);
            setIsLoading(false);
        }
    }

    return (
        <Fragment>
            {isLoading ? (
                <CircularProgress />
            ) : (
                <Tooltip
                    describeChild
                    title={t('You must select a tag, before making a screenshot')}
                    disableHoverListener={selectedBuildings.length !== 0}
                >
                    <span>
                        <Button
                            onClick={savePhoto}
                            startDecorator={<Photo />}
                            className="mapScreenshot"
                            disabled={selectedBuildings.length === 0}
                        >
                            {t('SAVE MAP IMAGE')}
                        </Button>
                    </span>
                </Tooltip>
            )}

            <Modal
                aria-labelledby="preview-modal"
                aria-describedby={`preview-modal-description`}
                open={isPreviewModalOpen}
                onClose={() => setIsPreviewModalOpen(false)}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <Sheet variant="outlined" sx={{ maxWidth: 500, borderRadius: 'md', p: 3, boxShadow: 'lg' }}>
                    <ModalClose variant="plain" sx={{ m: 1 }} />
                    <Typography component="h2" id="modal-title" level="h4" textColor="inherit" fontWeight="lg" mb={1}>
                        {t('Image Preview')}
                    </Typography>

                    <img src={image} alt={t('Image Preview')} width={400} />
                </Sheet>
            </Modal>
        </Fragment>
    );
}

const mapStateToProps = function (state: any) {
    return {
        selectedBuildings: state.tagger.selectedBuildings,
    };
};

const mapDispatchToProps = function (dispatch: any) {
    return {
        setErrorMessage: (errorMessage: string) => dispatch(setErrorMessage(errorMessage)),
        toggleShowOnlySelectedBuildings: () => dispatch(toggleShowOnlySelectedBuildings()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Screenshot);
