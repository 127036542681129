import { TYPES } from './global-tagger.actions';
import { Building, GeoLocation, Layers, RealEstate } from './global-tagger.dto';

export interface State {
    location: GeoLocation;
    errorMessage: string;
    realEstate: RealEstate;
    selectedBuildings: Building[];
    highlightedBuilding: string;
    showRoofSegments: boolean;
    useQA: boolean;
    isLoading: boolean;
    showOnlySelectedBuildings: boolean;
}

const initialState: State = {
    location: {
        address: 'Oberbilker Allee 244, 40227 Düsseldorf',
        lat: 51.2108431,
        lng: 6.802931999999999,
    },
    errorMessage: '',
    realEstate: {
        count: 0,
        buildings: [],
        locationSearch: {
            lat: 51.2108431,
            lng: 6.802931999999999,
            radius: 1000,
            layers: [Layers.SOLAR_POTENTIAL],
        },
    },
    selectedBuildings: [],
    highlightedBuilding: '',
    showRoofSegments: false,
    useQA: false,
    isLoading: false,
    showOnlySelectedBuildings: false,
};

export const tagger = (state: any = initialState, action: any) => {
    switch (action.type) {
        case TYPES.SET_LOCATION:
            return Object.assign({}, state, { location: action.location });

        case TYPES.LOAD_REAL_ESTATE_DATA_REQUESTED:
            return Object.assign({}, state, { isLoading: true });
        case TYPES.LOAD_REAL_ESTATE_DATA_SUCCEEDED:
            return Object.assign({}, state, {
                isLoading: false,
                realEstate: action.realEstate,
            });
        case TYPES.LOAD_REAL_ESTATE_DATA_FAILED:
            return Object.assign({}, state, {
                isLoading: false,
                errorMessage: action.errorMessage,
            });

        case TYPES.SET_SELECTED_PLOT:
            return Object.assign({}, state, { selectedBuilding: action.selectedBuilding });

        case TYPES.SET_HIGHLIGHTED_BUILDING:
            return Object.assign({}, state, {
                highlightedBuilding: action.highlightedBuilding,
            });

        case TYPES.SET_SHOW_ROOF_SEGMENTS:
            return Object.assign({}, state, {
                showRoofSegments: action.showRoofSegments,
            });

        case TYPES.ADD_BUILDING_TO_SELECTION:
            console.debug('Adding to selection: ' + action.selectedBuilding);
            if (!state.selectedBuildings.some((b: Building) => b.code === action.selectedBuilding.code)) {
                return {
                    ...state,
                    selectedBuildings: [...state.selectedBuildings, action.selectedBuilding],
                };
            }
            return state;

        case TYPES.LOAD_BUILDING_TO_SELECTION_REQUESTED:
            return Object.assign({}, state, { isLoading: true });
        case TYPES.LOAD_BUILDING_TO_SELECTION_SUCCEEDED:
            return Object.assign({}, state, {
                isLoading: false,
                selectedBuildings: [...state.selectedBuildings, action.selectedBuilding],
            });
        case TYPES.LOAD_BUILDING_TO_SELECTION_FAILED:
            return Object.assign({}, state, {
                isLoading: false,
                errorMessage: action.errorMessage,
            });

        case TYPES.REMOVE_BUILDING_FROM_SELECTION:
            console.debug('Removing from selection: ' + action.selectedBuilding);
            const updatedSelection = state.selectedBuildings.filter(
                (building: Building) => building.code !== action.selectedBuilding.code,
            );
            console.debug('Updated selection after removal:', updatedSelection);
            return {
                ...state,
                selectedBuildings: updatedSelection,
            };

        case TYPES.RESET_BUIDING_SELECTION:
            return Object.assign({}, state, { selectedBuildings: [] });

        case TYPES.TOGGLE_SHOW_ONLY_SELECTED_BUILDINGS:
            console.log('TOGGLE_SHOW_ONLY_SELECTED_BUILDINGS: ' + state.showOnlySelectedBuildings);
            return Object.assign({}, state, { showOnlySelectedBuildings: !state.showOnlySelectedBuildings });

        default:
            return state;
    }
};
