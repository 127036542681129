export const ROOF_TYPES = [
    'Satteldach',
    'Zwerchdach',
    'Zwerchgiebeldach',
    'Wiederkehr',
    'Schleppdach',
    'Kreuzdach',
    'Knickdach',
    'Walmdach',
    'Krüppelwalmdach',
    'Mansardgiebeldach',
    'Mansardwalmdach',
    'Krüppelmansarddach',
    'Mansardflachdach',
    'Mansarddach mit Schopf',
    'Fußwalmdach',
    'Pultdach',
    'Versetztes Pultdach',
    'Frackdach',
    'Schmetterlingsdach',
    'Zeltdach',
    'Nurdach',
    'Spitzbogentonnendach',
    'Tonnendach',
    'Halbtonnendach',
    'Bogendach',
    'Paralleldach',
    'Sheddach',
    'Grabendach',
    'Flachdach mit Dachüberstand',
    'Flachdach mit Attika',
    'Sonstige',
];

export const ROOF_COVERINGS = [
    'Tonziegel/Betonziegel',
    'Bieberschwanzziegel',
    'Schiefer',
    'Metalleindeckung: Metallziegel, Stahlblech',
    'Welleternit, Sandwichdach',
    'Bitumenbahn/Flachdach (bis 3°)',
    'Bitumenschindel/ Schrägdach (über 5°)',
    'Blechfalz',
    'Folie PVC',
    'Gründach',
    'Beton',
    'Sonstige',
];

export const STOERFLAECHEN = [
    'Schornsteinfegertritt',
    'SAT Schüssel (kann versetzt werden)',
    'SAT Schüssel (muss bestehen bleiben)',
    'Solarthermieanlage (kann rückgebaut werden)',
    'Solarthermieanlage (bleibt bestehen)',
    'Lüftungsauslass (kann überhaupt werden)',
    'Lüftungsauslass (benötigt Lüfterziegel)',
    'Blitzableiter',
    'Sekuranten',
    'Sonstige',
    'Dachfenster',
    'Gaube',
];

export const ELECTRIC_CONNECTION_TYPES = ['Niederspannungshauptverteilung (NSHV)', 'Unterverteilung'];
