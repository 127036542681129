import { Alert, Button, CircularProgress, FormControl, FormLabel, Grid, IconButton, Input, Typography } from '@mui/joy';
import React from 'react';
import { PvAnalysisVariationBuilding, PvAnalysisVariationRoofSegment } from '../pv-analysis.dto';
import { useTranslation } from 'react-i18next';
import { Delete } from '@mui/icons-material';
import {
    addBuildingToSelection,
    removeBuildingFromSelection,
    removeRoofSegmentFromBuildingSelection,
    updateSelectedBuilding,
} from '../pv-analysis.slice';
import { useAppDispatch, useAppSelector } from 'redux-app-hooks';
import { generateSimpleID } from 'common/generateSimpleID';

export default function SelectedBuildings() {
    const { editedVariant, isMapLoading } = useAppSelector((state) => state.pvAnalysis);
    const selectedBuildings = editedVariant.buildings;

    const dispatch = useAppDispatch();

    const { t } = useTranslation();

    const EMPTY_ROOF_SEGMENT: PvAnalysisVariationRoofSegment = {
        azimuthDegrees: 0,
        tiltDegrees: 0,
        kwhPerKwp: 0,
        kwp: 0,
    };

    const EMPTY_BUILDING: PvAnalysisVariationBuilding = {
        code: generateSimpleID(),
        name: `Virtual Building ${selectedBuildings.length + 1}`,
        geometry: {
            type: 'Polygon',
            coordinates: [],
        },
        buildingFunctionCode: '',
        roofSegments: [EMPTY_ROOF_SEGMENT],
    };

    function addEmptyBuilding() {
        dispatch(addBuildingToSelection(EMPTY_BUILDING));
    }

    function addRoofSegmentToBuilding(building: PvAnalysisVariationBuilding) {
        dispatch(
            updateSelectedBuilding({
                ...building,
                roofSegments: [
                    ...building.roofSegments,
                    {
                        ...EMPTY_ROOF_SEGMENT,
                    },
                ],
            }),
        );
    }

    function updateRoofSegment(
        building: PvAnalysisVariationBuilding,
        roofSegmentIndex: number,
        changes: Partial<PvAnalysisVariationRoofSegment>,
    ) {
        dispatch(
            updateSelectedBuilding({
                ...building,
                roofSegments: building.roofSegments.map((roofSegment, index) => {
                    if (index === roofSegmentIndex) {
                        return {
                            ...roofSegment,
                            ...changes,
                        };
                    }
                    return roofSegment;
                }),
            }),
        );
    }

    return (
        <Grid xs={12} container spacing={3}>
            {isMapLoading && <CircularProgress />}
            <Grid xs={12} container spacing={3} justifyContent="right">
                <Button variant="soft" onClick={() => addEmptyBuilding()} color="primary">
                    {t('Add Building')}
                </Button>
            </Grid>

            {selectedBuildings.length === 0 && (
                <Grid xs={12}>
                    <Alert>{t('Please, select or add a building')}.</Alert>
                </Grid>
            )}

            <Grid xs={12} container spacing={3}>
                {selectedBuildings.map((building, buildingIndex: number) => (
                    <Grid key={building.code} xs={12} container>
                        <Grid xs={12} direction="row" container>
                            <Grid xs={11.5}>
                                <Typography level="h3">
                                    {t('Building')} #{buildingIndex + 1} ({building.code})
                                </Typography>
                            </Grid>
                            <Grid xs={0.5}>
                                <IconButton
                                    variant="soft"
                                    aria-label={t('Remove building from this PV Analysis')}
                                    onClick={() => dispatch(removeBuildingFromSelection(building))}
                                >
                                    <Delete />
                                </IconButton>
                            </Grid>
                        </Grid>

                        <Grid xs={12} direction="row" container>
                            <Grid xs={2}>
                                <b>{t('Name')}:</b>
                            </Grid>
                            <Grid xs={4}>{building.name || 'N/a'}</Grid>
                            <Grid xs={2}>
                                <b>{t('Building Function')}:</b>
                            </Grid>
                            <Grid xs={4}>{building.buildingFunctionCode}</Grid>
                        </Grid>

                        {building.roofSegments.map((roofSegment, roofIndex) => (
                            <Grid xs={12} direction="row" container key={roofIndex}>
                                <Grid xs={12} direction="row" container>
                                    <Grid xs={11.5}>
                                        <Typography level="h4">
                                            {t('Roof Segement')} #{roofIndex + 1}
                                        </Typography>
                                    </Grid>
                                    <Grid xs={0.5}>
                                        <IconButton
                                            variant="soft"
                                            aria-label={t('Remove roof segment from this PV Analysis')}
                                            onClick={() =>
                                                dispatch(
                                                    removeRoofSegmentFromBuildingSelection({
                                                        selectedBuilding: building,
                                                        roofSegment,
                                                    }),
                                                )
                                            }
                                        >
                                            <Delete />
                                        </IconButton>
                                    </Grid>
                                </Grid>

                                <Grid xs={12} direction="row" container>
                                    <Grid xs={6}>
                                        <FormControl required>
                                            <FormLabel>{t('Azimuth')} (°)</FormLabel>
                                            <Input
                                                type="number"
                                                placeholder={t('Azimuth')}
                                                slotProps={{ input: { min: 0, max: 360, step: 0.01 } }}
                                                defaultValue={roofSegment.azimuthDegrees.toFixed(1)}
                                                onChange={(e) =>
                                                    updateRoofSegment(building, roofIndex, {
                                                        azimuthDegrees: parseFloat(e.target.value),
                                                    })
                                                }
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid xs={6}>
                                        <FormControl required>
                                            <FormLabel>{t('Tilt')} (°)</FormLabel>
                                            <Input
                                                type="number"
                                                placeholder={t('Tilt')}
                                                slotProps={{ input: { min: 0, max: 90, step: 0.01 } }}
                                                defaultValue={roofSegment.tiltDegrees.toFixed(1)}
                                                onChange={(e) =>
                                                    updateRoofSegment(building, roofIndex, {
                                                        tiltDegrees: parseFloat(e.target.value),
                                                    })
                                                }
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid xs={12} direction="row" container>
                                    <Grid xs={6}>
                                        <FormControl required>
                                            <FormLabel>{t('Peak Power (kWp)')}</FormLabel>
                                            <Input
                                                type="number"
                                                placeholder={t('kWp')}
                                                slotProps={{ input: { min: 0, step: 0.01 } }}
                                                defaultValue={roofSegment.kwp.toFixed(2)}
                                                onChange={(e) =>
                                                    updateRoofSegment(building, roofIndex, {
                                                        kwp: parseFloat(e.target.value),
                                                    })
                                                }
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid xs={6}>
                                        <FormControl required>
                                            <FormLabel>{t('Specific Yield (kWh/kWp)')}</FormLabel>
                                            <Input
                                                type="number"
                                                placeholder={t('kWh/kWp')}
                                                slotProps={{ input: { min: 1, step: 0.01 } }}
                                                defaultValue={roofSegment.kwhPerKwp.toFixed(2)}
                                                onChange={(e) =>
                                                    updateRoofSegment(building, roofIndex, {
                                                        kwhPerKwp: parseFloat(e.target.value),
                                                    })
                                                }
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}
                        <Grid xs={12} alignContent="center">
                            <Button variant="soft" onClick={() => addRoofSegmentToBuilding(building)}>
                                {t('Add roof segment')}
                            </Button>
                        </Grid>

                        <Grid xs={12}>&nbsp;</Grid>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    );
}
