import { Alert, Button, CircularProgress, Grid, Typography } from '@mui/joy';
import React from 'react';
import { PvAnalysisBuildingMetadata } from '../pv-analysis.dto';
import { useTranslation } from 'react-i18next';
import { importBuilding } from '../pv-analysis.slice';
import { useAppDispatch, useAppSelector } from 'redux-app-hooks';

export default function SelectedBuildingMetadata() {
    const { selectedBuildingMetadata, isMapLoading, errorMessage, isImportBuildingLoading } = useAppSelector(
        (state) => state.pvAnalysis,
    );

    const dispatch = useAppDispatch();

    const { t } = useTranslation();

    function importNewBuilding(buildingMetadata: PvAnalysisBuildingMetadata) {
        dispatch(importBuilding(buildingMetadata));
    }

    return (
        <Grid xs={12} container spacing={3}>
            {(isMapLoading || isImportBuildingLoading) && <CircularProgress />}

            {selectedBuildingMetadata === null && (
                <Grid xs={12}>
                    <Alert>{t('Please, select a building')}.</Alert>
                </Grid>
            )}
            {selectedBuildingMetadata !== null && (
                <Grid key={selectedBuildingMetadata.code} xs={12} container direction="column">
                    <Grid xs={11.5}>
                        <Typography level="h3">
                            {t('Building')} #({selectedBuildingMetadata.code})
                        </Typography>
                    </Grid>
                    <Grid container direction="row" spacing={1}>
                        <Grid xs={5}>
                            <b>{t('Building Function')}:</b>
                        </Grid>
                        <Grid xs={6}>{selectedBuildingMetadata.function}</Grid>
                    </Grid>
                    <Grid container direction="row" spacing={1}>
                        <Grid xs={5}>
                            <b>{t('Building File Path')}:</b>
                        </Grid>
                        <Grid xs={6}>{selectedBuildingMetadata.filePath}</Grid>
                    </Grid>
                    <Grid xs={12} alignContent="center">
                        <Button variant="soft" onClick={() => importNewBuilding(selectedBuildingMetadata)}>
                            {t('Import Building')}
                        </Button>
                    </Grid>
                    {errorMessage && (
                        <Grid xs={12}>
                            <Alert color="danger">{t(errorMessage)}</Alert>
                        </Grid>
                    )}
                </Grid>
            )}
        </Grid>
    );
}
