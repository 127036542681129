import React from 'react';
import { Tab, TabList, TabPanel, Tabs, Typography } from '@mui/joy';
import { AutoFixHigh, QueryStats } from '@mui/icons-material';
import { t } from 'i18next';

import { PVResultDto } from '../pv-calculator.dto';
import PVResultInsights from './PVResultInsights';
import PVResultProfitLossStatement from './PVResultProfitLossStatement';

export default function PVOptimizationInsights({
    result,
    onClickResultGraphDataPoint,
}: {
    result: PVResultDto;
    onClickResultGraphDataPoint?: (targetProduction: number, batteryCapacityFactor: number) => void;
}) {
    return (
        <Tabs>
            <TabList>
                <Tab>
                    <AutoFixHigh /> {t('Optimizer')}
                </Tab>
                <Tab>
                    <QueryStats /> {t('Profit/loss breakdown')}
                </Tab>
            </TabList>
            <TabPanel value={0}>
                {result.roi_insights && (
                    <PVResultInsights
                        roiInsights={result.roi_insights}
                        bestRateOfReturn={result.rate_of_return}
                        bestOwnConsumptionRatio={result.ratio_own_use}
                        bestTargetProduction={result.design_kwh_per_year_ac}
                        onClickDataPoint={onClickResultGraphDataPoint}
                    />
                )}
            </TabPanel>
            <TabPanel value={1}>
                {result.profit_loss_statement && (
                    <PVResultProfitLossStatement profitLossStatement={result.profit_loss_statement} />
                )}
                {!result.profit_loss_statement && (
                    <Typography>{t('This feature is only available on the PV test calculator')}</Typography>
                )}
            </TabPanel>
        </Tabs>
    );
}
