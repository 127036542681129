import React from 'react';
import { Sheet, IconButton, Typography, FormControl, Input, FormLabel } from '@mui/joy';
import { Delete } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import { BaseLoadProfileDto, LoadProfileType } from '../pv-calculator.dto';

export default function BaseLoad(props: {
    value: BaseLoadProfileDto;
    onChange: (loadProfile: BaseLoadProfileDto) => void;
    onDelete: () => void;
}) {
    const { t } = useTranslation();

    function onChange(value: string) {
        const parsed = parseFloat(value);

        props.onChange({
            type: LoadProfileType.BASE,
            annual_usage: isNaN(parsed) ? null : parsed,
        });
    }

    return (
        <Sheet variant="outlined" sx={{ p: 2 }}>
            <IconButton sx={{ position: 'absolute', top: 0, right: 0 }} onClick={() => props.onDelete()}>
                <Delete />
            </IconButton>
            <Typography level="h4">{t('Base usage')}</Typography>
            <FormControl>
                <FormLabel>{t('Base usage (kWh/a) - estimated if not provided')}</FormLabel>
                <Input
                    type="number"
                    value={props.value.annual_usage ?? ''}
                    onChange={(e) => onChange(e.target.value)}
                />
            </FormControl>
        </Sheet>
    );
}
