import { toCanvas } from 'html-to-image';

export declare type Options = {
    width: number;
    height: number;
};

/**
 * A hook that provides a screenshot function that uses SVG foreignObject renderer.
 *
 * Advantages:
 *  - It provides a consistent experience across different browser settings
 *  - You can specify an exact width and height of the screenshot
 *  - The content is rendered off-screen
 *
 * Disadvantages:
 *  - It doesn't support cross-origin content
 *
 * As an alternative, consider using the `useDOMRendererScreenshot` hook.
 */
export default function useSvgForeignObjectScreenshot() {
    return {
        screenshot: async (element: HTMLElement, options: Options) => {
            return await toCanvas(element, {
                backgroundColor: 'white',
                canvasWidth: options.width,
                canvasHeight: options.height,
                pixelRatio: 1,
            });
        },
    };
}
