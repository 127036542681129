import { API_BASE_URL, PV_CALCULATOR_API_BASE_URL, QA_PV_CALCULATOR_API_BASE_URL } from 'appConfig';
import { httpClient } from '../../infrastructure/admi-client/http-client';
import { LoadProfileDto, LoadProfileType, PVResultDto, RoofSegmentDto } from './pv-calculator.dto';

export const admiPVCalculatorService = {
    async doPVOptimization(
        roof_segments: RoofSegmentDto[],
        optimizer: string,
        building_type: string,
        load_profiles: LoadProfileDto[],
        chp_production_profile: number[] | null,
        funding_rate: number,
        limit_100kwp: boolean,
        target_production?: number,
        battery_kwp_factor?: number,
        latitude?: number,
        longitude?: number,
        useQA = false,
        sum_wb_hu?: number,
        floor_area?: number,
    ) {
        const response = await httpClient.post<PVResultDto>(
            '/v1/pv-optimization',
            {
                roof_segments,
                optimizer,
                building_type,
                load_profiles,
                funding_rate,
                limit_100kwp,
                target_production,
                battery_kwp_factor,
                latitude,
                longitude,
                sum_wb_hu,
                floor_area,
                chp_production_profile,
            },
            {
                baseURL: useQA ? QA_PV_CALCULATOR_API_BASE_URL : PV_CALCULATOR_API_BASE_URL,
                headers: {
                    // TODO: This API will in the future be done completely within the backend.
                    // Then, change the API key
                    'X-API_KEY': '9i90tgioufnqwh58sdg8yhajdnrh2ht4',
                },
            },
        );

        return response.data;
    },

    // @deprecated Use doPVOptimization instead
    async getRoofSegments(tag: string): Promise<[RoofSegmentDto[], number, [number, number]]> {
        const response = await httpClient.get<any>('/v1/pv-info', {
            params: {
                tag,
            },
            baseURL: API_BASE_URL,
        });

        return [
            response.data.map(
                (row: any) =>
                    ({
                        kwp: (row.kw_17 * 400) / 280 + row.kw_21_7,
                        kwh_kwp: row.kwh_kwp,
                        roof_pitch: row.neigung,
                        direction: row.richtung,
                        building_id: row.geb_id,
                    }) as RoofSegmentDto,
            ),
            response.data[0]?.sum_wb_hu,
            response.data[0]?.center,
        ];
    },

    errorResponseToDescription: (err: any) => {
        // Is it a PV-calculator specific error?
        if (err.detail && err.detail.error_code) {
            switch (err.detail.error_code) {
                case 'NO_SUITABLE_ROOF_SEGMENTS':
                    return 'No suitable roof segments found. This could also occur if the entered tag is not available.';
                case 'NO_PLACEMENT_STRATEGY_APPLICABLE':
                    return 'No placement found that fulfills all constraints (e.g. roof is large enough).';
                case 'NOT_WITHIN_OPTIMIZATION_CONSTRAINTS':
                    return 'The optimizer could not find a design that matches all the criteria. Please check the inputs (e.g. too small usage).';
                default:
                    return `${err.detail.description} (${err.detail.error_code})`;
            }
        }

        if (typeof err === 'string') {
            return err;
        }

        return JSON.stringify(err);
    },

    isBaseUsageEstimated(loadProfiles: LoadProfileDto[]) {
        const loadProfile = loadProfiles.find((p) => p.type === LoadProfileType.BASE);
        return typeof loadProfile?.annual_usage !== 'number';
    },
};
