import { Button, Checkbox, FormControl, FormLabel, Input, Option, Select, Stack, Switch, Typography } from '@mui/joy';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'redux-app-hooks';

import { downloadFileFromBlob } from 'common/downloadFile';
import { formCheckbox, formFieldBuilder, handleSelect } from 'common/formikUtils';
import ImageSelector from 'common/components/ImageSelector';

import { admiFeasibilityStudyService } from './admi-feasibility-study.service';
import { FlatRoofData, TiltedRoofData } from './feasibility-study.dto';
import { INITIAL_VALUES } from './formData';

import { NotificationSeverity, showSnackbar } from 'features/notifications/notifications.slice';
import { ELECTRIC_CONNECTION_TYPES, ROOF_COVERINGS, ROOF_TYPES } from 'features/onsite/selectOptions';
import { BuildingType } from 'features/pv-calculator/pv-calculator.dto';
import { ContactAutocomplete } from 'features/crm/components/ContactAutocomplete';
import { Contact } from 'features/crm/crm.dto';

export function FeasibilityStudyCreatePage() {
    const dispatch = useAppDispatch();

    const formik = useFormik({
        initialValues: INITIAL_VALUES,
        onSubmit: async (values) => {
            console.log(values);

            try {
                const [documentData, filename] = await admiFeasibilityStudyService.createFeasibilityStudy(values);
                downloadFileFromBlob(documentData, filename);

                dispatch(
                    showSnackbar({
                        message: t('Feasiblity study has been successfully created'),
                        severity: NotificationSeverity.Success,
                    }),
                );
            } catch (error: any) {
                console.error(error);
                dispatch(
                    showSnackbar({
                        message: t('Failed to create feasibility study: {{error}}', {
                            error: JSON.stringify(error?.message),
                        }),
                        severity: NotificationSeverity.Error,
                    }),
                );
            }
        },
    });

    const { t } = useTranslation();
    const formField = (name: string) => formFieldBuilder(formik, name);

    const [isFlatRoof, setIsFlatRoof] = useState<boolean>(true);
    useEffect(() => {
        formik.setFieldValue(
            'roof.roofTypeSpecific',
            isFlatRoof
                ? ({ roofTiltIsSmallEnough: false, atticHeight: 0 } as FlatRoofData)
                : ({
                      raftersHaveSufficientStrength: false,
                      imageRaftersWithScale: '',
                      underlayHasGoodQuality: false,
                      imageUnderlay: '',
                  } as TiltedRoofData),
        );
    }, [isFlatRoof]);

    function handleContactSelect(contact: Contact) {
        formik.setFieldValue('contact', {
            name: contact.name,
            address: `${contact.streetAddress}, ${contact.zipCodeAndCity}`,
            email: contact.email,
            phone: contact.phone,
            jobTitle: contact.jobTitle,
        });
    }

    return (
        <form onSubmit={formik.handleSubmit}>
            <Stack
                spacing={1.5}
                sx={{
                    display: 'flex',
                    maxWidth: '900px',
                    mx: 'auto',
                    px: { xs: 2, md: 6 },
                    py: { xs: 2, md: 3 },
                }}
            >
                <Typography level="h1">{t('Create feasibility study')}</Typography>

                <Typography level="h3">{t('Project information')}</Typography>
                <FormControl required>
                    <FormLabel>{t('Municipality name')}</FormLabel>
                    <Input placeholder={t('Municipality name')} {...formField('project.municipalityName')} />
                </FormControl>
                <FormControl required>
                    <FormLabel>{t('Project name')}</FormLabel>
                    <Input placeholder={t('Project name')} {...formField('project.name')} />
                </FormControl>
                <FormControl required>
                    <FormLabel>{t('Project address')}</FormLabel>
                    <Input placeholder={t('Project address')} {...formField('project.address')} />
                </FormControl>
                <FormControl required>
                    <FormLabel>{t('Google Maps screenshot')}</FormLabel>
                    <ImageSelector name="project.imageGoogleMapsScreenshot" formik={formik} />
                </FormControl>

                <Typography level="h3">{t('Contact information')}</Typography>
                <ContactAutocomplete onSelectContact={handleContactSelect} />

                <FormControl required>
                    <FormLabel>{t('Contact name')}</FormLabel>
                    <Input placeholder={t('Contact name')} {...formField('contact.name')} />
                </FormControl>
                <FormControl required>
                    <FormLabel>{t('Contact address')}</FormLabel>
                    <Input placeholder={t('Contact address')} {...formField('contact.address')} />
                </FormControl>
                <FormControl required>
                    <FormLabel>{t('Contact email')}</FormLabel>
                    <Input type="email" placeholder={t('Contact email')} {...formField('contact.email')} />
                </FormControl>
                <FormControl required>
                    <FormLabel>{t('Contact phone')}</FormLabel>
                    <Input placeholder={t('Contact phone')} {...formField('contact.phone')} />
                </FormControl>
                <FormControl required>
                    <FormLabel>{t('Contact job title')}</FormLabel>
                    <Input placeholder={t('Contact job title')} {...formField('contact.jobTitle')} />
                </FormControl>

                <Typography level="h3">{t('Potential analysis result')}</Typography>
                <FormControl required>
                    <FormLabel>{t('Building type')}</FormLabel>
                    <Select
                        sx={{ width: '20rem' }}
                        value={formik.values.potentialAnalysisResult.buildingType}
                        onChange={handleSelect(formik, 'potentialAnalysisResult.buildingType')}
                    >
                        {Object.values(BuildingType).map((buildingTypeName) => (
                            <Option key={buildingTypeName} value={buildingTypeName}>
                                {t(buildingTypeName)}
                            </Option>
                        ))}
                    </Select>
                </FormControl>
                <FormControl required>
                    <FormLabel>{t('Consumption (kWh/a)')}</FormLabel>
                    <Input
                        type="number"
                        placeholder={t('Consumption (kWh/a)')}
                        {...formField('potentialAnalysisResult.consumptionKwhPerYear')}
                    />
                </FormControl>
                <FormControl required>
                    <FormLabel>{t('PV peak power (kWp)')}</FormLabel>
                    <Input
                        type="number"
                        placeholder={t('PV peak power (kWp)')}
                        {...formField('potentialAnalysisResult.pvKwp')}
                    />
                </FormControl>
                <FormControl required>
                    <FormLabel>{t('Battery capacity (kWh)')}</FormLabel>
                    <Input
                        type="number"
                        placeholder={t('Battery capacity (kWh)')}
                        {...formField('potentialAnalysisResult.batteryKwh')}
                    />
                </FormControl>
                <FormControl required>
                    <FormLabel>{t('PV production (kWh/a)')}</FormLabel>
                    <Input
                        type="number"
                        placeholder={t('PV production (kWh/a)')}
                        {...formField('potentialAnalysisResult.productionKwhPerYear')}
                    />
                </FormControl>
                <FormControl required>
                    <FormLabel>{t('CO2 savings')}</FormLabel>
                    <Input
                        type="number"
                        placeholder={t('CO2 savings')}
                        {...formField('potentialAnalysisResult.co2Savings')}
                    />
                </FormControl>
                <FormControl required>
                    <FormLabel>{t('Initial investment (€)')}</FormLabel>
                    <Input
                        type="number"
                        placeholder={t('Initial investment (€)')}
                        {...formField('potentialAnalysisResult.initialInvestment')}
                    />
                </FormControl>
                <FormControl required>
                    <FormLabel>{t('Balance over 30 years (€)')}</FormLabel>
                    <Input
                        type="number"
                        placeholder={t('Balance over 30 years (€)')}
                        {...formField('potentialAnalysisResult.balance30Years')}
                    />
                </FormControl>
                <FormControl required>
                    <FormLabel>{t('Rate of return (%)')}</FormLabel>
                    <Input
                        type="number"
                        placeholder={t('Rate of return (%)')}
                        {...formField('potentialAnalysisResult.rateOfReturn')}
                    />
                </FormControl>
                <FormControl required>
                    <FormLabel>{t('Amortization years')}</FormLabel>
                    <Input
                        type="number"
                        placeholder={t('Amortization years')}
                        {...formField('potentialAnalysisResult.amortizationYears')}
                    />
                </FormControl>
                <FormControl required>
                    <FormLabel>{t('Self-consumption rate (%)')}</FormLabel>
                    <Input
                        type="number"
                        placeholder={t('Self-consumption rate (%)')}
                        {...formField('potentialAnalysisResult.selfConsumptionRate')}
                    />
                </FormControl>

                <Typography level="h3">{t('Onsite information')}</Typography>
                <FormControl required>
                    <FormLabel>{t('Author name')}</FormLabel>
                    <Input placeholder={t('Author name')} {...formField('onsite.authorName')} />
                </FormControl>
                <FormControl required>
                    <FormLabel>{t('Onsite date')}</FormLabel>
                    <Input type="date" placeholder={t('Onsite date')} {...formField('onsite.onsiteDate')} />
                </FormControl>
                <Typography level="body-md">{t('Access to')}</Typography>
                <Checkbox label={t('Utility room')} {...formCheckbox(formik, 'onsite.accessToUtilityRoom')} />
                <Checkbox label={t('Meter cabinet')} {...formCheckbox(formik, 'onsite.accessToMeterCabinet')} />
                <Checkbox label={t('Attic')} {...formCheckbox(formik, 'onsite.accessToAttic')} />
                <Checkbox label={t('Roof')} {...formCheckbox(formik, 'onsite.accessToRoof')} />

                <Typography level="h3">{t('Roof information')}</Typography>
                <Stack direction="row" spacing={2}>
                    <FormControl required>
                        <FormLabel>{t('Roof type')}</FormLabel>
                        <Select
                            sx={{ width: '20rem' }}
                            value={formik.values.roof.roofType}
                            onChange={handleSelect(formik, 'roof.roofType')}
                        >
                            {ROOF_TYPES.map((roofType) => (
                                <Option key={roofType} value={roofType}>
                                    {roofType}
                                </Option>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl required>
                        <FormLabel>{t('Roof covering')}</FormLabel>
                        <Select
                            sx={{ width: '20rem' }}
                            value={formik.values.roof.roofCovering}
                            onChange={handleSelect(formik, 'roof.roofCovering')}
                        >
                            {ROOF_COVERINGS.map((roofCovering) => (
                                <Option key={roofCovering} value={roofCovering}>
                                    {roofCovering}
                                </Option>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl required>
                        <FormLabel>{t('Number of selected roof segments')}</FormLabel>
                        <Input
                            type="number"
                            placeholder={t('Number of selected roof segments')}
                            {...formField('roof.numberOfSelectedRoofSegments')}
                        />
                    </FormControl>
                </Stack>
                <FormControl required>
                    <FormLabel>{t('Roof drone photo')}</FormLabel>
                    <ImageSelector name="roof.imageDroneRoof" formik={formik} />
                </FormControl>

                <Typography
                    component="label"
                    startDecorator={
                        <Switch
                            sx={{ ml: 1 }}
                            checked={isFlatRoof}
                            onChange={(event) => setIsFlatRoof(event.target.checked)}
                        />
                    }
                >
                    {t('Flat roof')}
                </Typography>
                {isFlatRoof && (
                    <Checkbox
                        label={t('Roof tilt is small enough')}
                        {...formCheckbox(formik, 'roof.roofTypeSpecific.roofTiltIsSmallEnough')}
                    />
                )}
                {!isFlatRoof && (
                    <>
                        <Checkbox
                            label={t('Rafters have sufficient strength')}
                            {...formCheckbox(formik, 'roof.roofTypeSpecific.raftersHaveSufficientStrength')}
                        />
                        <FormControl required>
                            <FormLabel>{t('Photo of rafters with scale')}</FormLabel>
                            <ImageSelector name="roof.roofTypeSpecific.imageRaftersWithScale" formik={formik} />
                        </FormControl>
                        <Checkbox
                            label={t('Underlay has good quality')}
                            {...formCheckbox(formik, 'roof.roofTypeSpecific.underlayHasGoodQuality')}
                        />
                        <FormControl required>
                            <FormLabel>{t('Photo of underlay')}</FormLabel>
                            <ImageSelector name="roof.roofTypeSpecific.imageUnderlay" formik={formik} />
                        </FormControl>
                    </>
                )}

                <Typography level="h3">{t('Module assignment')}</Typography>
                <FormControl required>
                    <FormLabel>{t('Photo of possible module assignment')}</FormLabel>
                    <ImageSelector name="imagePossibleModuleAssignment" formik={formik} />
                </FormControl>

                <Typography level="h3">{t('Static requirements')}</Typography>
                <Checkbox
                    label={t('Fulfills static requirements')}
                    {...formCheckbox(formik, 'fulfillsStaticRequirements')}
                />

                <Typography level="h3">{t('Cable routing')}</Typography>
                <Checkbox
                    label={t('Has suitable cable routing')}
                    {...formCheckbox(formik, 'cableRouting.hasSuitableCableRouting')}
                />
                <FormControl required>
                    <FormLabel>{t('Photo of cable routing 1')}</FormLabel>
                    <ImageSelector name="cableRouting.imageCableRouting1" formik={formik} />
                </FormControl>
                <FormControl required>
                    <FormLabel>{t('Photo of cable routing 2')}</FormLabel>
                    <ImageSelector name="cableRouting.imageCableRouting2" formik={formik} />
                </FormControl>

                <Typography level="h3">{t('Electric installation')}</Typography>
                <Checkbox
                    label={t('Has suitable connection point')}
                    {...formCheckbox(formik, 'electricInstallation.hasSuitableConnectionPoint')}
                />
                <FormControl required>
                    <FormLabel>{t('Connection point type')}</FormLabel>
                    <Select
                        sx={{ width: '20rem' }}
                        value={formik.values.electricInstallation.connectionPointType}
                        onChange={handleSelect(formik, 'electricInstallation.connectionPointType')}
                    >
                        {ELECTRIC_CONNECTION_TYPES.map((connectionType) => (
                            <Option key={connectionType} value={connectionType}>
                                {connectionType}
                            </Option>
                        ))}
                    </Select>
                </FormControl>
                <FormControl required>
                    <FormLabel>{t('Photo of connection point')}</FormLabel>
                    <ImageSelector name="electricInstallation.imageConnectionPoint" formik={formik} />
                </FormControl>
                <Checkbox
                    label={t('Has suitable installation zone')}
                    {...formCheckbox(formik, 'electricInstallation.hasSuitableInstallationZone')}
                />
                <FormControl required>
                    <FormLabel>{t('Installation room')}</FormLabel>
                    <Input
                        placeholder={t('Installation room')}
                        {...formField('electricInstallation.installationRoom')}
                    />
                </FormControl>
                <FormControl required>
                    <FormLabel>{t('Photo of installation zone components 1')}</FormLabel>
                    <ImageSelector name="electricInstallation.imageInstallationZoneComponents1" formik={formik} />
                </FormControl>
                <FormControl required>
                    <FormLabel>{t('Photo of installation zone components 2')}</FormLabel>
                    <ImageSelector name="electricInstallation.imageInstallationZoneComponents2" formik={formik} />
                </FormControl>

                <Typography level="h3">{t('Fire protection')}</Typography>
                <Checkbox
                    label={t('Has suitable fire protection cable routing')}
                    {...formCheckbox(formik, 'fireProtection.hasSuitableFireProtectionCableRouting')}
                />
                <FormControl required>
                    <FormLabel>{t('Photo of areas with fire protection measures')}</FormLabel>
                    <ImageSelector name="fireProtection.imageAreasWithFireProtectionMeasures" formik={formik} />
                </FormControl>
                <Checkbox
                    label={t('Has installation zone for fire protection')}
                    {...formCheckbox(formik, 'fireProtection.hasFireProtectionInstallationZone')}
                />

                <Button type="submit" color="primary" loading={formik.isSubmitting}>
                    {t('Generate document')}
                </Button>
            </Stack>
        </form>
    );
}
