import { Grid } from '@mui/joy';
import Search from './components/Search';
import React from 'react';
import MetaDataMap from './components/MetaDataMap';
import SelectedBuildingMetadata from './components/SelectedBuildingMetadata';
import styles from './NewBuilding.module.css';

export default function NewBuilding() {
    return (
        <Grid container direction="column" spacing={2}>
            <Grid xs={12} spacing={2}>
                <Search />
            </Grid>
            <Grid container direction="row" spacing={1} className={styles.tagging}>
                <Grid xs={7} className={styles.map}>
                    <MetaDataMap />
                </Grid>
                <Grid xs={4}>
                    <SelectedBuildingMetadata />
                </Grid>
            </Grid>
        </Grid>
    );
}
